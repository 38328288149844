import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {withRouter} from "react-router";
import {spendsService} from "../../services/spends.service";
import PageTitle from "../../components/ecocoin/PageTitle";
import {authenticationService} from "../../services/authentication.service";
import {boolTypes} from "../../helpers/data";
import ActiveFormatter from "../../components/ActiveFormatter";


class Spends extends Component {

    state = {
        spends: []
    }

    componentWillMount() {
        spendsService.list()
            .then(spends => spends.map(spend => {
                spend.amount /= process.env.REACT_APP_ECOCOIN_DIVIDER
                return spend
            }))
            .then(spends => {
                this.setState({
                    spends: spends
                })
                console.log("loaded spend list", spends)
            })
    }

    render() {
        const {spends} = this.state
        const options = {
            sizePerPage: 20,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: true,
            onRowClick: function (row, columnindex, rowIndex, e) {
                console.log("ROW CLICK", e)
                window.location = "/#/spends/" + row.spendId
            },
            defaultSortName: 'orderNo',
            defaultSortOrder: 'desc'
        }

        return (
            <div className="container-fluid">
                <PageTitle title="All Spends" buttonText="Add" onButtonClick={this.addSpend} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={spends}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}>
                                    <TableHeaderColumn
                                        dataField='spendId'
                                        isKey
                                        width="3%"
                                        dataSort
                                        dataAlign="right">
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='orderNo'
                                        width="5%"
                                        dataSort
                                        dataAlign="right">
                                        Order
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='name'
                                        width="15%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='voucher'
                                        width="10%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Voucher
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='amount'
                                        width="7%"
                                        filter={{
                                            type: 'NumberFilter',
                                            numberComparators: ['=', '>=', '<'],
                                            defaultValue: {comparator: '>='}
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Price (ECOs)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='clientId'
                                        width={authenticationService.isAdmin ? "5%" : "0%"}
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Client
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='active'
                                        width="5%"
                                        dataSort
                                        dataAlign="center"
                                        filter={{type: 'SelectFilter', options: boolTypes}}
                                        dataFormat={this.activeFormatter}>
                                        Active
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    activeFormatter = (cell, row) => {
        return (
            <ActiveFormatter active={cell}/>
        );
    }

    addSpend = () => {
        window.location = "/#/spends/add"
    }
}

export default withRouter(Spends)