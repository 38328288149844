import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';


class Nav extends Component {

    state = {};

    render() {
        let {location} = this.props;
        let withCatalog = <span/>
        if (process.env.REACT_APP_WITH_CATALOG === 'true') {
            withCatalog = <div>
                <li className={location.pathname === '/' ? 'active' : null}>
                    <Link to="/">
                        <i className="pe-7s-graph"></i>
                        <p>Dashboard</p>
                    </Link>
                </li>
                <li className={this.isPathActive('/components') || this.state.componentMenuOpen ? 'active' : null}>
                    <a href="dummy" onClick={() => this.setState({componentMenuOpen: !this.state.componentMenuOpen})}
                       data-toggle="collapse">
                        <i className="pe-7s-plugin"></i>
                        <p>
                            Components
                            <b className="caret"></b>
                        </p>
                    </a>
                    <Collapse in={this.state.componentMenuOpen}>
                        <div>
                            <ul className="nav">
                                <li className={this.isPathActive('/components/buttons') ? 'active' : null}>
                                    <Link to="/components/buttons">Buttons</Link>
                                </li>
                                <li className={this.isPathActive('/components/grid') ? 'active' : null}>
                                    <Link to="/components/grid">Grid System</Link>
                                </li>
                                <li className={this.isPathActive('/components/icons') ? 'active' : null}>
                                    <Link to="/components/icons">Icons</Link>
                                </li>
                                <li className={this.isPathActive('/components/notifications') ? 'active' : null}>
                                    <Link to="/components/notifications">Notifications</Link>
                                </li>
                                <li className={this.isPathActive('/components/panels') ? 'active' : null}>
                                    <Link to="/components/panels">Panels</Link>
                                </li>
                                <li className={this.isPathActive('/components/sweetalert') ? 'active' : null}>
                                    <Link to="/components/sweetalert">Sweet Alert</Link>
                                </li>
                                <li className={this.isPathActive('/components/typography') ? 'active' : null}>
                                    <Link to="/components/typography">Typography</Link>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li className={this.isPathActive('/forms') || this.state.formMenuOpen ? 'active' : null}>
                    <a href="dummy" onClick={() => this.setState({formMenuOpen: !this.state.formMenuOpen})}
                       data-toggle="collapse">
                        <i className="pe-7s-note2"></i>
                        <p>Forms <b className="caret"></b></p>
                    </a>
                    <Collapse in={this.state.formMenuOpen}>
                        <div>
                            <ul className="nav">
                                <li className={this.isPathActive('/forms/regular-forms') ? 'active' : null}>
                                    <Link to="/forms/regular-forms">Regular Forms</Link>
                                </li>
                                <li className={this.isPathActive('/forms/extended-forms') ? 'active' : null}>
                                    <Link to="/forms/extended-forms">Extended Forms</Link>
                                </li>
                                <li className={this.isPathActive('/forms/validation-forms') ? 'active' : null}>
                                    <Link to="/forms/validation-forms">Validation Forms</Link>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li className={this.isPathActive('/tables') || this.state.tableMenuOpen ? 'active' : null}>
                    <a href="dummy" onClick={() => this.setState({tableMenuOpen: !this.state.tableMenuOpen})}
                       data-toggle="collapse">
                        <i className="pe-7s-news-paper"></i>
                        <p>Tables <b className="caret"></b></p>
                    </a>
                    <Collapse in={this.state.tableMenuOpen}>
                        <div>
                            <ul className="nav">
                                <li className={this.isPathActive('/tables/regular-tables') ? 'active' : null}>
                                    <Link to="/tables/regular-tables">Regular Table</Link>
                                </li>
                                <li className={this.isPathActive('/tables/extended-tables') ? 'active' : null}>
                                    <Link to="/tables/extended-tables">Extended Tables</Link>
                                </li>
                                <li className={this.isPathActive('/tables/fixed-data-table') ? 'active' : null}>
                                    <Link to="/tables/react-bootstrap-table">React Bootstrap Table</Link>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li className={this.isPathActive('/maps') || this.state.mapMenuOpen ? 'active' : null}>
                    <a href="dummy" onClick={() => this.setState({mapMenuOpen: !this.state.mapMenuOpen})}
                       data-toggle="collapse">
                        <i className="pe-7s-map-marker"></i>
                        <p>Map <b className="caret"></b></p>
                    </a>
                    <Collapse in={this.state.mapMenuOpen}>
                        <div>
                            <ul className="nav">
                                <li className={this.isPathActive('/maps/google-map') ? 'active' : null}>
                                    <Link to="/maps/google-map">Google Map</Link>
                                </li>
                                <li className={this.isPathActive('/maps/vector-map') ? 'active' : null}>
                                    <Link to="/maps/vector-map">Vector Map</Link>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li className={this.isPathActive('/charts') ? 'active' : null}>
                    <Link to="/charts">
                        <i className="pe-7s-graph"></i>
                        <p>Charts</p>
                    </Link>
                </li>
                <li className={this.isPathActive('/calendar') ? 'active' : null}>
                    <Link to="/calendar">
                        <i className="pe-7s-date"></i>
                        <p>Calendar</p>
                    </Link>
                </li>
            </div>
        }
        return (
            <ul className="nav">
                <li className={location.pathname === '/' ? 'active' : null}>
                    <Link to="/">
                        <i className="pe-7s-graph"></i>
                        <p>Dashboard</p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/earns') ? 'active' : null}>
                    <Link to="/earns">
                        <i className="pe-7s-graph"></i>
                        <p>Earns</p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/spends') ? 'active' : null}>
                    <Link to="/spends">
                        <p>
                            <i className="pe-7s-graph"></i>
                            Spends
                        </p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/transactions') ? 'active' : null}>
                    <Link to="/transactions">
                        <p>
                            <i className="pe-7s-graph"></i>
                            Transactions
                        </p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/balances') ? 'active' : null}>
                    <Link to="/balances">
                        <p>
                            <i className="pe-7s-graph"></i>
                            Balances
                        </p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/users') ? 'active' : null}>
                    <Link to="/users">
                        <i className="pe-7s-graph"></i>
                        <p>Users</p>
                    </Link>
                </li>
                <li className={location.pathname.startsWith('/teams') ? 'active' : null}>
                    <Link to="/teams">
                        <i className="pe-7s-graph"></i>
                        <p>Teams</p>
                    </Link>
                </li>

                {withCatalog}
            </ul>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

export default withRouter(Nav);