import React from 'react';

const Footer = () => (
    <footer style={{marginTop: 30 + 'px'}} className="footer">
        <div className="container-fluid">
            <nav className="pull-left">
            </nav>
            <p className="copyright pull-right">
                © {new Date().getFullYear()} <a href="https://www.ecocoin.com">The ECO Coin Project - Do Good, Get
                Paid</a>
            </p>
        </div>
    </footer>
);

export default Footer;