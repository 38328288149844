import React from 'react'
import {authenticationService} from "../../services/authentication.service";
import FormRow from "../../components/ecocoin/FormRow";
import PageTitle from "../../components/ecocoin/PageTitle";
import PageFooter from "../../components/ecocoin/PageFooter";


class TeamAdd extends React.Component {

    state = {
        team: {
            name: '',
            clientId: 1
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle title="Add Team"/>
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Team name" type="text" value={this.state.team.name}
                                             onChange={this.setName}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <FormRow label="Client ID" type="number" value={this.state.team.clientId}
                                                 onChange={this.setClientId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter buttonText="Add" cancelText="Cancel" onCancelClick={this.cancel}/>
                </form>
            </div>

        )
    }

    setName = (e) => {
        this.setState({
            team: {
                ...this.state.team,
                name: e.target.value
            }
        })
    }

    setClientId = (e) => {
        this.setState({
            team: {
                ...this.state.team,
                clientId: e.target.value
            }
        })
    }

    cancel = e => {
        e.preventDefault()
        window.history.back()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("submit", this.state.team)
        authenticationService.insertTeam(this.state.team)
            .then(e => window.history.back())
    }
}

export default TeamAdd
