import React from 'react';

const SocialButtons = () => (
  <div>
    <div className="header">
      <h4 className="title">Social buttons</h4>
    </div>

    <div className="content buttons-with-margin">
      <div className="row">
        <div className="col-md-3 social-buttons-demo">
          <h4 className="title"><small> Filled </small></h4>
          <button className="btn btn-social btn-fill btn-twitter">
            <i className="fa fa-twitter"></i> Connect with Twitter
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-facebook">
            <i className="fa fa-facebook-square"></i> Share · 2.2k
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-google">
            <i className="fa fa-google-plus-square"></i> Share on Google+
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-linkedin">
            <i className="fa fa-linkedin-square"></i> Connect with Linkedin
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-pinterest">
            <i className="fa fa-pinterest"></i> Pint it · 212
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-youtube">
            <i className="fa fa-youtube-play"></i> View on Youtube
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-tumblr">
            <i className="fa fa-tumblr-square"></i> Repost
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-github">
            <i className="fa fa-github"></i> Connect with Github
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-behance">
            <i className="fa fa-behance-square"></i> Follow us
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-dribbble">
            <i className="fa fa-dribbble"></i> Find us on Dribbble
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-reddit">
            <i className="fa fa-reddit"></i> Repost · 232
          </button>
          <br />
          <button className="btn btn-social btn-fill btn-stumbleupon">
            <i className="fa fa-stumbleupon"></i> View on StumbleUpon
          </button>
          <br />
        </div>

        <div className="col-md-1 social-buttons-demo">
          <h4 className="title"><small>Light</small></h4>
          <button className="btn btn-social btn-round btn-twitter">
            <i className="fa fa-twitter"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-facebook">
            <i className="fa fa-facebook"> </i>
          </button><br />
          <button className="btn btn-social btn-round btn-google">
            <i className="fa fa-google-plus"> </i>
          </button><br />
          <button className="btn btn-social btn-round btn-linkedin">
            <i className="fa fa-linkedin"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-pinterest">
            <i className="fa fa-pinterest"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-youtube">
            <i className="fa fa-youtube"> </i>
          </button><br />
          <button className="btn btn-social btn-round btn-tumblr">
            <i className="fa fa-tumblr"> </i>
          </button><br />
          <button className="btn btn-social btn-round btn-github">
            <i className="fa fa-github"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-behance">
            <i className="fa fa-behance"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-dribbble">
            <i className="fa fa-dribbble"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-reddit">
            <i className="fa fa-reddit"></i>
          </button><br />
          <button className="btn btn-social btn-round btn-stumbleupon">
            <i className="fa fa-stumbleupon"></i>
          </button><br />
        </div>

        <div className="col-md-1 social-buttons-demo">
          <h4 className="title"><small>Simple</small></h4>
          <button className="btn btn-social btn-simple btn-twitter">
            <i className="fa fa-twitter"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-facebook">
            <i className="fa fa-facebook-square"> </i>
          </button><br />
          <button className="btn btn-social btn-simple btn-google">
            <i className="fa fa-google-plus-square"> </i>
          </button><br />
          <button className="btn btn-social btn-simple btn-linkedin">
            <i className="fa fa-linkedin-square"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-pinterest">
            <i className="fa fa-pinterest"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-youtube">
            <i className="fa fa-youtube"> </i>
          </button><br />
          <button className="btn btn-social btn-simple btn-tumblr">
            <i className="fa fa-tumblr-square"> </i>
          </button><br />
          <button className="btn btn-social btn-simple btn-github">
            <i className="fa fa-github"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-behance">
            <i className="fa fa-behance"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-dribbble">
            <i className="fa fa-dribbble"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-reddit">
            <i className="fa fa-reddit"></i>
          </button><br />
          <button className="btn btn-social btn-simple btn-stumbleupon">
            <i className="fa fa-stumbleupon"></i>
          </button><br />
        </div>

        <div className="col-md-3 social-buttons-demo">
          <h4 className="title"><small>With Text</small></h4>
          <button className="btn btn-social btn-simple btn-twitter">
            <i className="fa fa-twitter"></i> Connect with Twitter
          </button><br />
          <button className="btn btn-social btn-simple btn-facebook">
            <i className="fa fa-facebook-square"></i> Share · 2.2k
          </button><br />
          <button className="btn btn-social btn-simple btn-google">
            <i className="fa fa-google-plus-square"></i> Share on Google+
          </button><br />
          <button className="btn btn-social btn-simple btn-linkedin">
            <i className="fa fa-linkedin-square"></i> Connect with Linkedin
          </button><br />
          <button className="btn btn-social btn-simple btn-pinterest">
            <i className="fa fa-pinterest"></i> Pint it · 212
          </button><br />
          <button className="btn btn-social btn-simple btn-youtube">
            <i className="fa fa-youtube-play"></i> View on Youtube
          </button><br />
          <button className="btn btn-social btn-simple btn-tumblr">
            <i className="fa fa-tumblr-square"></i> Repost
          </button><br />
          <button className="btn btn-social btn-simple btn-github">
            <i className="fa fa-github"></i> Connect with Github
          </button><br />
          <button className="btn btn-social btn-simple btn-behance">
            <i className="fa fa-behance-square"></i> Follow us
          </button><br />
          <button className="btn btn-social btn-simple btn-dribbble">
            <i className="fa fa-dribbble"></i> Find us on Dribbble
          </button><br />
          <button className="btn btn-social btn-simple btn-reddit">
            <i className="fa fa-reddit"></i> Repost · 232
          </button><br />
          <button className="btn btn-social btn-simple btn-stumbleupon">
            <i className="fa fa-stumbleupon"></i> View on StumbleUpon
          </button><br />
        </div>
      </div>
    </div>
  </div>
);

export default SocialButtons;