import React from 'react';
import TransactionChart from "./TransactionChart";


const Dashboard = () => (
    <div className="content">
        <TransactionChart/>
    </div>
);

export default Dashboard;