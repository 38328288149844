import React from "react";

function PageTitle(props) {
    let button = <span/>
    if (props.buttonText) {
        if(props.onButtonClick) {
            button = <button className="btn btn-info btn-fill btn-wd" onClick={props.onButtonClick}>{props.buttonText.toUpperCase()}</button>
        } else {
            button = <button type="submit" className="btn btn-info btn-fill btn-wd">{props.buttonText.toUpperCase()}</button>
        }
    }

    let button2 = <span/>
    if (props.button2Text) {
        if(props.onButton2Click) {
            button2 = <button className="btn btn-info btn-wd" onClick={props.onButton2Click}>{props.button2Text.toUpperCase()}</button>
        } else {
            button2 = <button type="submit" className="btn btn-info btn-wd">{props.button2Text.toUpperCase()}</button>
        }
    }

    let backButton = <span/>
    if (props.withBackButton) {
        backButton = <span>
            <a href="dummy" onClick={props.onBackButtonClick}>
                <i className="pe-7s-left-arrow"/>
            </a>&nbsp;&nbsp;
        </span>
    }

    return (
        <div className="row">
            <div className="col-sm-9">
                <h2>
                    {backButton}
                    {props.title}
                    <small>
                        <sup>&nbsp;&nbsp;{props.subTitle}</sup>
                    </small>
                </h2>
            </div>
            <div className="col-sm-3">
                <div style={{marginTop: 15 + 'px', marginBottom: 5 + 'px'}} className="text-right">
                    {button2}&nbsp;
                    {button}
                </div>
            </div>
        </div>
    )
}

export default PageTitle