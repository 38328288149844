import {combineDates, stringifySingleDate} from "../../helpers/earnDatesFormatter"
import React from "react"


function EarnDates(props) {
    const {timeConstraint} = props
    return (
        <div>
            {combineDates(timeConstraint)
                .map((combinedDate, i) =>
                    <div key={i}>
                        {stringifySingleDate(combinedDate)}
                    </div>
                )}
        </div>
    )
}

export default EarnDates