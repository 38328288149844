import {authHeader, authHeaderOnly, handleResponse} from '../helpers'


export const spendsService = {
    list,
    get,
    update,
    insert,
    uploadImage,
    maxOrder
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_SPENDS_LIST, requestOptions)
        .then(handleResponse)
}

function get(spendId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_SPENDS_GET + spendId, requestOptions)
        .then(handleResponse)
}

function update(spend) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(spend)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_SPENDS_UPDATE + spend.spendId, requestOptions)
        .then(handleResponse)
}

function insert(spend) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(spend)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_SPENDS_INSERT, requestOptions)
        .then(handleResponse)
}

function uploadImage(file) {

    // add file to FormData object
    const fd = new FormData();
    fd.append('file', file);

    // send `POST` request
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_UPLOADIMAGE, {
        method: 'POST',
        headers: authHeaderOnly(),
        body: fd
    })
        .then(handleResponse)
}

function maxOrder() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_SPENDS_MAXORDER, requestOptions)
        .then(handleResponse)
}
