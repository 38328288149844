import React from 'react';
import LoginForm from "./LoginForm";
import {authenticationService} from "../../services/authentication.service";


const onSubmit = values => {
    authenticationService.login(values.email, values.password)
        .then(user => {
            console.log("LOGGED IN SUCCESS", user)
            window.location = "/#/"
        })
        .catch(error => {
            console.log("Error logging in. Error = ", error)
        })
}

const Login = () => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                <h1>ECO Coin Admin Interface</h1>
            </div>
            <div className="col-md-12">
                <LoginForm onSubmit={onSubmit}/>
            </div>
        </div>
    </div>
);

export default Login