import React from 'react'
import ViewRow from "../../components/ecocoin/ViewRow"
import {earnsService} from "../../services/earns.service"
import EarnDates from "../../components/ecocoin/EarnDates"
import PageTitle from "../../components/ecocoin/PageTitle";
import CodesEditor from "../../components/ecocoin/CodesEditor";
import {withRouter} from "react-router-dom";
import {authenticationService} from "../../services/authentication.service";


class EarnView extends React.Component {

    state = {
        earn: {
            nameEn: '',
            nameNl: '',
            subtitleEn: '',
            subtitleNl: '',
            locationEn: '',
            locationNl: '',
            descriptionEn: '',
            descriptionNl: '',
            tagsEn: '',
            tagsNl: '',
            co2ReductionDescEn: '',
            co2ReductionDescNl: '',
            clientId: 1,
            co2ReductionAmount: 0,
            amount: 0,
            active: true,
            imageUrl: '',
            usageRestriction: 'none',
            earnDates: [],
            orderNo: 0
        }
    }

    componentWillMount() {
        earnsService.get(this.props.match.params.id).then(earn => {
            console.log("Loaded earn", earn)
            this.setState({earn: earn})
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle
                    title={this.state.earn.nameEn}
                    buttonText="Edit"
                    onButtonClick={this.gotoEdit}
                    button2Text="Clone"
                    onButton2Click={this.clone}
                    withBackButton="true"
                    onBackButtonClick={this.goBack}
                    subTitle={"EARN-" + this.state.earn.earnId}
                />

                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>English</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Name" value={this.state.earn.nameEn}/>
                                    <ViewRow label="Subtitle" value={this.state.earn.subtitleEn}/>
                                    <ViewRow label="Location" value={this.state.earn.locationEn}/>
                                    <ViewRow label="Description" type="html" value={this.state.earn.descriptionEn}/>
                                    <ViewRow label="Tags" value={this.state.earn.tagsEn}/>
                                    <ViewRow label="CO2e reduction description"
                                             value={this.state.earn.co2ReductionDescEn}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>Dutch</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Name" value={this.state.earn.nameNl}/>
                                    <ViewRow label="Subtitle" value={this.state.earn.subtitleNl}/>
                                    <ViewRow label="Location" value={this.state.earn.locationNl}/>
                                    <ViewRow label="Description" type="html" value={this.state.earn.descriptionNl}/>
                                    <ViewRow label="Tags" value={this.state.earn.tagsNl}/>
                                    <ViewRow label="CO2e reduction description"
                                             value={this.state.earn.co2ReductionDescNl}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Earn amount (ECOs)"
                                             value={this.state.earn.amount / process.env.REACT_APP_ECOCOIN_DIVIDER}/>
                                    <ViewRow label="CO2e reduction amount" type="gramms"
                                             value={this.state.earn.co2ReductionAmount}/>
                                    <ViewRow label="Usage restrictions" value={this.state.earn.usageRestriction}/>
                                    <ViewRow label="Active" value={this.state.earn.active ? "yes" : "no"}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <ViewRow label="Client ID" value={this.state.earn.clientId}/>
                                    </div>
                                    <ViewRow label="Order No." value={this.state.earn.orderNo}/>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>Opening Times</h4>
                                </div>
                                <EarnDates timeConstraint={this.state.earn.earnDates}/>
                            </div>
                        </div>
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>Codes</h4>
                                </div>
                                <CodesEditor earnId={this.state.earn.earnId}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="header">
                                <h4>Image</h4>
                            </div>
                            <div className="content">
                                <ViewRow type="image-wide"
                                         value={process.env.REACT_APP_IMAGE_BASE + this.state.earn.imageUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoEdit = () => {
        window.location = "/#/earns/" + this.props.match.params.id + "/edit"
    }

    clone = () => {
        this.props.history.push({
            pathname: '/earns/add',
            state: this.state
        })
    }

    goBack = e => {
        e.preventDefault()
        window.history.back()
    }
}

export default withRouter(EarnView)

