import React from 'react'
import FormRow from "../../components/ecocoin/FormRow";
import PageTitle from "../../components/ecocoin/PageTitle";
import PageFooter from "../../components/ecocoin/PageFooter";
import {transactionsService} from "../../services/transactions.service";


class TransactionAdd extends React.Component {

    state = {
        transaction: {
            type: 'deposit',
            debitorId: 0,
            creditorId: 0,
            amount: 0
        }
    }

    transactionTypes = [
        {value: 'deposit', text: 'Deposit'},
        {value: 'withdraw', text: 'Withdraw'}
    ]

    render() {
        return (
            <div className="container-fluid">
                <PageTitle title="Add Transaction"/>
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Type" type="select" options={this.transactionTypes}
                                             value={this.state.transaction.type} onChange={this.setTransactionType}/>
                                    <FormRow label="Debitor" type="number" value={this.state.transaction.debitorId}
                                             onChange={this.setDebitorId}/>
                                    <FormRow label="Creditor" type="number" value={this.state.transaction.creditorId}
                                             onChange={this.setCreditorId}/>
                                    <FormRow label="Amount (ECOs)" type="number"
                                             value={this.state.transaction.amount / process.env.REACT_APP_ECOCOIN_DIVIDER}
                                             onChange={this.setAmount}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter buttonText="Add" cancelText="Cancel" onCancelClick={this.cancel}/>
                </form>
            </div>
        )
    }

    setTransactionType = (e) => {
        this.setState({
            transaction: {
                ...this.state.transaction,
                type: e.target.value
            }
        })
    }

    setDebitorId = (e) => {
        this.setState({
            transaction: {
                ...this.state.transaction,
                debitorId: e.target.value
            }
        })
    }

    setCreditorId = (e) => {
        this.setState({
            transaction: {
                ...this.state.transaction,
                creditorId: e.target.value
            }
        })
    }

    setAmount = (e) => {
        this.setState({
            transaction: {
                ...this.state.transaction,
                amount: e.target.value * process.env.REACT_APP_ECOCOIN_DIVIDER
            }
        })
    }

    cancel = e => {
        e.preventDefault()
        window.history.back()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("submit", this.state.transaction)
        transactionsService.add(this.state.transaction)
            .then(e => window.history.back())
    }
}

export default TransactionAdd
