import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {withRouter} from "react-router";
import {earnsService} from "../../services/earns.service";
import PageTitle from "../../components/ecocoin/PageTitle";
import {authenticationService} from "../../services/authentication.service";
import {boolTypes} from "../../helpers/data";
import ActiveFormatter from "../../components/ActiveFormatter";


class Earns extends Component {

    state = {
        earns: []
    }

    componentWillMount() {
        earnsService.list()
            .then(earns => earns.map(earn => {
                earn.amount /= process.env.REACT_APP_ECOCOIN_DIVIDER
                earn.co2ReductionAmount /= 1000.0
                return earn;
            }))
            .then(earns => {
                this.setState({
                    earns: earns
                })
            })
    }

    render() {
        const {earns} = this.state;
        const options = {
            sizePerPage: 20,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: true,
            onRowClick: function (row, columnindex, rowIndex, e) {
                console.log("ROW CLICK", e)
                window.location = "/#/earns/" + row.earnId
            },
            defaultSortName: 'orderNo',
            defaultSortOrder: 'desc',
            onSortChange: this.onSortChange,
            onFilterChange: this.onFilterChange,
            onPageChange: this.onPageChange
        }

        return (
            <div className="container-fluid">
                <PageTitle title="All Earns" buttonText="Add" onButtonClick={this.addEarn}/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={earns}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}>
                                    <TableHeaderColumn
                                        dataField='earnId'
                                        isKey
                                        width="3%"
                                        dataSort
                                        dataAlign="right">
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='orderNo'
                                        width="5%"
                                        dataSort
                                        dataAlign="right">
                                        Order
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='name'
                                        width="15%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='amount'
                                        width="7%"
                                        filter={{
                                            type: 'NumberFilter',
                                            numberComparators: ['=', '>=', '<'],
                                            defaultValue: {comparator: '>='}
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Price (ECOs)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='co2ReductionAmount'
                                        width="7%"
                                        filter={{
                                            type: 'NumberFilter',
                                            numberComparators: ['=', '>=', '<'],
                                            defaultValue: {comparator: '>='}
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        CO2 (kg)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='clientId'
                                        width={authenticationService.isAdmin ? "5%" : "0%"}
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Client
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='active'
                                        width="5%"
                                        dataSort
                                        dataAlign="center"
                                        filter={{type: 'SelectFilter', options: boolTypes}}
                                        dataFormat={this.activeFormatter}>
                                        Active
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    activeFormatter = (cell, row) => {
        return (
            <ActiveFormatter active={cell}/>
        );
    }

    onSortChange = (sortName, sortOrder) => {
        console.log("Sort change:", sortName, sortOrder)
    }

    onFilterChange = (filterObj) => {
        console.log("Filter change:", filterObj)
    }

    onPageChange = (page, sizePerPage) => {
        console.log("Page change:", page, sizePerPage)
    }

    addEarn = () => {
        window.location = "/#/earns/add"
    }

}

export default withRouter(Earns)