import {authHeader, handleResponse} from '../helpers'


export const transactionsService = {
    list,
    userBalance,
    add
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TRANSACTIONS_LIST, requestOptions)
        .then(handleResponse)
}

function userBalance(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TRANSACTIONS_USER + userId + "/balance", requestOptions)
        .then(handleResponse)
}

function add(transaction) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(transaction)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TRANSACTIONS_ADD, requestOptions)
        .then(handleResponse)
}
