import { authenticationService } from '../services/authentication.service';


export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if ([401].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized response returned from api
                authenticationService.logout()
                window.location = "/#/login"
            }

            const error = data || response.statusText
            return Promise.reject(error)
        }

        return data
    })
}