import {authenticationService} from '../services/authentication.service'

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return {
            ...plainHeader(),
            Authorization: `Bearer ${currentUser.token}`
        }
    } else {
        return plainHeader();
    }
}

export function authHeaderOnly() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return {
            Authorization: `Bearer ${currentUser.token}`
        }
    } else {
        return plainHeader();
    }
}

export function plainHeader() {
    return {
        'Content-Type': 'application/json'
    }
}
