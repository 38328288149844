import React from 'react'
import FormRow from "../../components/ecocoin/FormRow";
import {earnsService} from "../../services/earns.service";
import {spendsService} from "../../services/spends.service";
import PageTitle from "../../components/ecocoin/PageTitle";
import PageFooter from "../../components/ecocoin/PageFooter";
import ViewRow from "../../components/ecocoin/ViewRow";
import {authenticationService} from "../../services/authentication.service";


class SpendEdit extends React.Component {

    state = {
        spend: {
            nameEn: '',
            nameNl: '',
            locationEn: '',
            locationNl: '',
            descriptionEn: '',
            descriptionNl: '',
            clientId: 1,
            voucher: '',
            amount: 0,
            active: true,
            imageUrl: '',
            totalAvailable: 0,
            totalUsagePerUser: 0,
            usage: 0,
            orderNo: 0
        }
    }

    componentWillMount() {
        spendsService.get(this.props.match.params.id).then(spend => {
            console.log("Loaded apend", spend)
            this.setState({spend: spend})
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle title="Edit Spend" subTitle={"SPEND-"+this.state.spend.spendId}/>
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="content">
                                    <div className="header">
                                        <h4>English</h4>
                                    </div>
                                    <FormRow label="Name" type="text" value={this.state.spend.nameEn}
                                             onChange={this.setNameEn}/>
                                    <FormRow label="Location" type="text" value={this.state.spend.locationEn}
                                             onChange={this.setLocationEn}/>
                                    <FormRow label="Description (HTML)" type="textarea"
                                             value={this.state.spend.descriptionEn} onChange={this.setDescriptionEn}
                                             rows="15"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="content">
                                    <div className="header">
                                        <h4>Dutch</h4>
                                    </div>
                                    <FormRow label="Name" type="text" value={this.state.spend.nameNl}
                                             onChange={this.setNameNl}/>
                                    <FormRow label="Location" type="text" value={this.state.spend.locationNl}
                                             onChange={this.setLocationNl}/>
                                    <FormRow label="Description (HTML)" type="textarea"
                                             value={this.state.spend.descriptionNl} onChange={this.setDescriptionNl}
                                             rows="15"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="content">
                                    <div className="header">
                                        <h4>General</h4>
                                    </div>
                                    <FormRow label="Price (ECOs)" type="number"
                                             value={this.state.spend.amount / process.env.REACT_APP_ECOCOIN_DIVIDER}
                                             onChange={this.setAmount}/>
                                    <FormRow label="Voucher" type="text"
                                             value={this.state.spend.voucher}
                                             onChange={this.setVoucher}/>
                                    <FormRow label="Active" type="checkbox" value={this.state.spend.active}
                                             onChange={this.setActive}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <FormRow label="Client ID" type="number" value={this.state.spend.clientId}
                                                 onChange={this.setClientId}/>
                                    </div>
                                    <FormRow label="Order No." type="number" value={this.state.spend.orderNo}
                                             onChange={this.setOrderNo}/>
                                    <FormRow label="Total available" type="number"
                                             value={this.state.spend.totalAvailable}
                                             onChange={this.setTotalAvailable}/>
                                    <FormRow label="Total usage per user" type="number"
                                             value={this.state.spend.totalUsagePerUser}
                                             onChange={this.setTotalUsagePerUser}/>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="content">
                                    <div className="header">
                                        <h4>Image</h4>
                                    </div>
                                    <ViewRow type="image-wide" value={process.env.REACT_APP_IMAGE_BASE + this.state.spend.imageUrl}/>
                                    <FormRow id="imageUrlField" label="Image" type="file"
                                             value={this.state.spend.imageUrl}
                                             onChange={this.setImageUrl}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter buttonText="Save" cancelText="Cancel" onCancelClick={this.cancel}/>
                </form>
            </div>

        )
    }

    setNameEn = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                nameEn: e.target.value
            }
        })
    }

    setLocationEn = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                locationEn: e.target.value
            }
        })
    }

    setDescriptionEn = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                descriptionEn: e.target.value
            }
        })
    }

    setNameNl = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                nameNl: e.target.value
            }
        })
    }

    setLocationNl = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                locationNl: e.target.value
            }
        })
    }

    setDescriptionNl = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                descriptionNl: e.target.value
            }
        })
    }

    setClientId = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                clientId: e.target.value
            }
        })
    }

    setOrderNo = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                orderNo: e.target.value
            }
        })
    }

    setVoucher = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                voucher: e.target.value
            }
        })
    }

    setAmount = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                amount: e.target.value * process.env.REACT_APP_ECOCOIN_DIVIDER
            }
        })
    }

    setActive = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                active: e.target.checked
            }
        })
    }

    setImageUrl = (e) => {

        // select file input
        const input = document.getElementById('imageUrlField')
        earnsService.uploadImage(input.files[0])
            .then(result => {
                this.setState({
                    spend: {
                        ...this.state.spend,
                        imageUrl: result.filename
                    }
                })
                console.log("set image url: ", result.filename)
            })
    }

    setTotalAvailable = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                totalAvailable: e.target.value
            }
        })
    }

    setTotalUsagePerUser = (e) => {
        this.setState({
            spend: {
                ...this.state.spend,
                totalUsagePerUser: e.target.value
            }
        })
    }

    cancel = e => {
        e.preventDefault()
        window.history.back()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("submit", this.state.spend)
        spendsService.update(this.state.spend)
            .then(e => window.history.back())
    }
}

export default SpendEdit
