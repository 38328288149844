import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import cx from 'classnames';
import {setMobileNavVisibility} from '../../reducers/Layout';
import {withRouter} from 'react-router-dom';
import Switch from "react-router-dom/Switch";

import Header from './Header';
import Footer from './Footer';
import SideBar from '../../components/SideBar';


/**
 * Pages
 */
import Dashboard from '../Dashboard';
import Components from '../Components';
import UserProfile from '../UserProfile';
import {authenticationService} from "../../services/authentication.service";
import Users from '../Users'
import UserView from "../Users/UserView";
import UserEdit from "../Users/UserEdit";
import UserAdd from "../Users/UserAdd";
import Teams from "../Teams"
import TeamView from "../Teams/TeamView";
import TeamEdit from "../Teams/TeamEdit";
import TeamAdd from "../Teams/TeamAdd";
import Earns from "../Earns"
import EarnAdd from "../Earns/EarnAdd";
import EarnView from "../Earns/EarnView";
import EarnEdit from "../Earns/EarnEdit";
import Spends from "../Spends"
import SpendAdd from "../Spends/SpendAdd";
import SpendView from "../Spends/SpendView";
import SpendEdit from "../Spends/SpendEdit";
import Transactions from "../Transactions";
import TransactionAdd from "../Transactions/TransactionAdd";
import AllBalances from "../Balances/AllBalances";


const Main = ({
                  match,
                  mobileNavVisibility,
                  hideMobileMenu,
                  history
              }) => {

    if (authenticationService.currentUserValue === null) {
        window.location = '/#/login'
        return (<p>not logged in</p>)
    }

    history.listen(() => {
        if (mobileNavVisibility === true) {
            hideMobileMenu();
        }
    });
    return (
        <div className={cx({
            'nav-open': mobileNavVisibility === true
        })}>
            <div className="wrapper">
                <div className="close-layer" onClick={hideMobileMenu}></div>
                <SideBar logout={logout}/>

                <div className="main-panel">
                    <Header logout={logout}/>
                    <Switch>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/components" component={Components}/>
                        <Route path="/profile" component={UserProfile}/>
                        <Route path="/users" exact component={Users}/>
                        <Route path="/users/add" exact component={UserAdd}/>
                        <Route path="/users/:id" exact component={UserView}/>
                        <Route path="/users/:id/edit" component={UserEdit}/>
                        <Route path="/teams" exact component={Teams}/>
                        <Route path="/teams/add" exact component={TeamAdd}/>
                        <Route path="/teams/:id" exact component={TeamView}/>
                        <Route path="/teams/:id/edit" component={TeamEdit}/>
                        <Route path="/earns" exact component={Earns}/>
                        <Route path="/earns/add" exact component={EarnAdd}/>
                        <Route path="/earns/:id" exact component={EarnView}/>
                        <Route path="/earns/:id/edit" component={EarnEdit}/>
                        <Route path="/spends" exact component={Spends}/>
                        <Route path="/spends/add" exact component={SpendAdd}/>
                        <Route path="/spends/:id" exact component={SpendView}/>
                        <Route path="/spends/:id/edit" component={SpendEdit}/>
                        <Route path="/transactions" exact component={Transactions}/>
                        <Route path="/transactions/add" exact component={TransactionAdd}/>
                        <Route path="/balances" exact component={AllBalances}/>
                    </Switch>
                    <Footer/>
                </div>
            </div>
        </div>
    )
};

const mapStateToProp = state => ({
    mobileNavVisibility: state.Layout.mobileNavVisibility
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    hideMobileMenu: () => dispatch(setMobileNavVisibility(false))
});

const logout = (e) => {
    authenticationService.logout()
    window.location = "/#/login"
}

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));