import React from 'react'
import crossfilter from 'crossfilter2'
import {scaleLinear, scaleTime} from "d3-scale"
import BarChart from "@david.kucsai/dc-react/lib/BarChart"
import {transactionsService} from "../../services/transactions.service";
import {timeDays} from "d3-time"
import dc from 'dc'
import PieChart from "@david.kucsai/dc-react/lib/PieChart";
import RowChart from "@david.kucsai/dc-react/lib/RowChart";
import NumberDisplay from "@david.kucsai/dc-react/lib/NumberDisplay";
import 'dc/dist/style/dc.css'
import '../../assets/styles/charts.css'
import {authenticationService} from "../../services/authentication.service";


class TransactionChart extends React.Component {

    ecoColor = '#efb135'

    ecoColors = ['#efb135', '#efdb35', '#c8ef35', '#74ef35', '#35ef6a', '#35efc9', '#35b2ef', '#3553ef', '#7535ef', '#c035ef', '#ef35b0', '#ef3556', '#ef6d35']

    state = {
        initialized: false,
        earliest: null,
        latest: null,
        dayDimension: null,
        dayGroup: null
    }

    ecoValue = tx => tx.amount / 1000000000000000000

    componentDidMount() {
        transactionsService.list()
            .then(transactions => transactions.map(tx => {
                let date = new Date(tx.createdAt)
                let name = tx.name;
                if (name === undefined || name === null) {
                    name = 'none'
                }
                let clientId
                let userId
                if (tx.type === 'earn') {
                    clientId = tx.debitorId
                    userId = tx.creditorId
                } else if (tx.type === 'spend') {
                    clientId = tx.creditorId
                    userId = tx.debitorId
                } else if (tx.type === 'deposit') {
                    clientId = tx.creditorId
                    userId = tx.creditorId
                    name = 'Deposit'
                } else if (tx.type === 'withdraw') {
                    clientId = ''
                    userId = tx.debitorId
                    name = 'Withdrawal'
                }
                let teamId = '' + tx.teamId
                if (teamId === 'undefined') {
                    teamId = 'None'
                }
                let testVariation = ('' + tx.testVariation).trim()
                if (testVariation === 'undefined' || testVariation === '') {
                    testVariation = 'None'
                }

                return {
                    amount: tx.amount,
                    createdAt: new Date(tx.createdAt),
                    day: new Date(date).setHours(0, 0, 0, 0),
                    hour: date.getHours(),
                    type: tx.type,
                    name: name,
                    clientId: clientId,
                    userId: userId,
                    co2ReductionAmount: tx.co2ReductionAmount,
                    teamId: teamId,
                    testVariation: testVariation
                }
            }))
            .then(transactions => {
                let data = crossfilter(transactions)

                let dayDimension = data.dimension(tx => tx.day)
                let dayGroup = dayDimension.group().reduceSum(this.ecoValue)
                let hourDimension = data.dimension(tx => tx.hour)
                let hourGroup = hourDimension.group().reduceSum(this.ecoValue)
                let typeDimension = data.dimension(tx => tx.type)
                let typeGroup = typeDimension.group().reduceSum(this.ecoValue)
                let nameDimension = data.dimension(tx => tx.name)
                let nameGroup = nameDimension.group().reduceSum(this.ecoValue)
                let userDimension = data.dimension(tx => tx.userId)
                let userGroup = userDimension.group().reduceSum(this.ecoValue)
                let clientDimension = data.dimension(tx => tx.clientId)
                let clientGroup = clientDimension.group().reduceSum(this.ecoValue)
                let testVariationDimension = data.dimension(tx => tx.testVariation)
                let testVariationGroup = testVariationDimension.group().reduceSum(this.ecoValue)
                let teamDimension = data.dimension(tx => tx.teamId)
                let teamGroup = teamDimension.group().reduceSum(this.ecoValue)
                let earliest = new Date(transactions[0].createdAt.setHours(0, 0, 0, 0))
                let latest = new Date(transactions[transactions.length - 1].createdAt.setHours(23, 59, 59, 999))
                let earliestZoom = earliest

                let allAmount = data.groupAll().reduce(
                    function (p, v) {
                        ++p.count
                        p.amount += v.amount
                        p.co2ReductionAmount += v.co2ReductionAmount
                        if (p.users.has(v.userId)) {
                            p.users.set(v.userId, p.users.get(v.userId) + 1)
                        } else {
                            p.users.set(v.userId, 1);
                        }
                        return p
                    },
                    function (p, v) {
                        --p.count
                        p.amount -= v.amount
                        p.co2ReductionAmount -= v.co2ReductionAmount
                        const count = p.users.get(v.userId)
                        if (count > 1) {
                            p.users.set(v.userId, count - 1)
                        } else {
                            p.users.delete(v.userId)
                        }
                        return p
                    },
                    function () {
                        return {count: 0, amount: 0, co2ReductionAmount: 0, users: new Map()}
                    }
                )

                this.setState({
                    initialized: true,
                    earliest: earliest,
                    latest: latest,
                    dayZoomActive: false,
                    earliestZoom: earliestZoom,
                    latestZoom: latest,
                    allAmount: allAmount,
                    dayDimension: dayDimension,
                    dayGroup: dayGroup,
                    hourDimension: hourDimension,
                    hourGroup: hourGroup,
                    hourFilterActive: false,
                    typeDimension: typeDimension,
                    typeGroup: typeGroup,
                    typeFilterActive: false,
                    nameDimension: nameDimension,
                    nameGroup: nameGroup,
                    productFilterActive: false,
                    userDimension: userDimension,
                    userGroup: userGroup,
                    userFilterActive: false,
                    clientDimension: clientDimension,
                    clientGroup: clientGroup,
                    testVariationDimension: testVariationDimension,
                    testVariationGroup: testVariationGroup,
                    teamDimension: teamDimension,
                    teamGroup: teamGroup,
                    clientFilterActive: false
                })
            })
    }

    render() {
        let dayChart = null
        let rangeChart = null
        let dayFilterReset = null
        let dayFilterHint = null
        let hourChart = null
        let hourFilterReset = null
        let typeChart = null
        let typeFilterReset = null
        let productChart = null
        let productFilterReset = null
        let userChart = null
        let userFilterReset = null
        let clientChart = null
        let clientFilterReset = null
        let testVariationChart = null
        let testVariationFilterReset = null
        let teamChart = null
        let teamFilterReset = null
        let totalVolumeDisplay = null
        let totalCountDisplay = null
        let totalCO2Display = null
        let totalUsersDisplay = null

        if (this.state.initialized) {
            totalVolumeDisplay =
                <NumberDisplay
                    group={this.state.allAmount}
                    valueAccessor={this.ecoValue}
                    formatNumber={n => n.toFixed(0) + ' ECOs'}
                />
            totalCountDisplay =
                <NumberDisplay
                    group={this.state.allAmount}
                    valueAccessor={(d) => d.count}
                    formatNumber={n => n.toFixed(0)}
                />
            totalCO2Display =
                <NumberDisplay
                    group={this.state.allAmount}
                    valueAccessor={(d) => d.co2ReductionAmount}
                    formatNumber={n => (n / 1000).toFixed(3) + ' kg'}
                />
            totalUsersDisplay =
                <NumberDisplay
                    group={this.state.allAmount}
                    valueAccessor={(d) => d.users.size}
                    formatNumber={n => n.toFixed(0)}
                />

            dayChart =
                <BarChart
                    dimension={this.state.dayDimension}
                    group={this.state.dayGroup}
                    x={scaleTime().domain([this.state.earliestZoom, this.state.latestZoom])}
                    xUnits={timeDays}
                    margins={{left: 70, top: 10, right: 20, bottom: 20}}
                    elasticY={true}
                    brushOn={false}
                    renderHorizontalGridLines={true}
                    colors={this.ecoColor}
                    gap={5}
                    title={(d) => this.volumeFormatter({
                        key: new Date(d.key).toDateString(),
                        value: d.value
                    })}
                />
            rangeChart =
                <BarChart
                    chartGroup='rangeChart'
                    dimension={this.state.dayDimension}
                    group={this.state.dayGroup}
                    x={scaleTime().domain([this.state.earliest, this.state.latest])}
                    xUnits={timeDays}
                    margins={{left: 82, top: 10, right: 20, bottom: 20}}
                    elasticY={true}
                    height={70}
                    colors={this.ecoColor}
                    onFiltered={(chart, filter) => {
                        if (filter !== null) {
                            this.setState({
                                dayZoomActive: true,
                                earliestZoom: new Date(filter[0].setHours(0, 0, 0, 0)),
                                latestZoom: new Date(filter[1].setHours(23, 59, 59, 999))
                            })
                        } else {
                            this.setState({
                                dayZoomActive: false,
                                earliestZoom: this.state.earliest,
                                latestZoom: this.state.latest
                            })
                        }
                    }}
                />
            if (this.state.dayZoomActive) {
                dayFilterReset =
                    <button onClick={() => dc.filterAll('rangeChart')}>reset</button>
                dayFilterHint =
                    <span>
                        {this.state.earliestZoom.toDateString()} to {this.state.latestZoom.toDateString()}
                    </span>
            } else {
                dayFilterHint =
                    <span>Select a time range in the chart above</span>
            }

            hourChart =
                <BarChart
                    chartGroup='hourChart'
                    dimension={this.state.hourDimension}
                    group={this.state.hourGroup}
                    x={scaleLinear().domain([0, 24])}
                    elasticY={true}
                    margins={{left: 50, top: 10, right: 10, bottom: 20}}
                    colors={this.ecoColor}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            hourFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.hourFilterActive) {
                hourFilterReset =
                    <button onClick={() => dc.filterAll('hourChart')}>reset</button>
            }

            typeChart =
                <PieChart
                    chartGroup='typeChart'
                    dimension={this.state.typeDimension}
                    group={this.state.typeGroup}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    title={this.volumeFormatter}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            typeFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.typeFilterActive) {
                typeFilterReset =
                    <button onClick={() => dc.filterAll('typeChart')}>reset</button>
            }

            productChart =
                <RowChart
                    chartGroup='productChart'
                    dimension={this.state.nameDimension}
                    group={this.state.nameGroup}
                    title={this.volumeFormatter}
                    elasticX={true}
                    height={820}
                    cap={30}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    fixedBarHeight={20}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            productFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.productFilterActive) {
                productFilterReset =
                    <button onClick={() => dc.filterAll('productChart')}>reset</button>
            }

            userChart =
                <RowChart
                    chartGroup='userChart'
                    dimension={this.state.userDimension}
                    group={this.state.userGroup}
                    title={(d) => this.volumeFormatter({key: 'User ' + d.key, value: d.value})}
                    elasticX={true}
                    height={820}
                    cap={30}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    fixedBarHeight={20}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            userFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.userFilterActive) {
                userFilterReset =
                    <button onClick={() => dc.filterAll('userChart')}>reset</button>
            }

            clientChart =
                <RowChart
                    chartGroup='clientChart'
                    dimension={this.state.clientDimension}
                    group={this.state.clientGroup}
                    title={(d) => this.volumeFormatter({key: 'Client ' + d.key, value: d.value})}
                    elasticX={true}
                    height={820}
                    cap={30}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    fixedBarHeight={20}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            clientFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.clientFilterActive) {
                clientFilterReset =
                    <button onClick={() => dc.filterAll('clientChart')}>reset</button>
            }

            teamChart =
                <RowChart
                    chartGroup='teamChart'
                    dimension={this.state.teamDimension}
                    group={this.state.teamGroup}
                    title={(d) => this.volumeFormatter({key: 'Team ' + d.key, value: d.value})}
                    elasticX={true}
                    height={820}
                    cap={30}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    fixedBarHeight={20}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            teamFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.teamFilterActive) {
                teamFilterReset =
                    <button onClick={() => dc.filterAll('teamChart')}>reset</button>
            }

            testVariationChart =
                <RowChart
                    chartGroup='testVariationChart'
                    dimension={this.state.testVariationDimension}
                    group={this.state.testVariationGroup}
                    title={(d) => this.volumeFormatter({key: 'Test variation ' + d.key, value: d.value})}
                    elasticX={true}
                    cap={7}
                    ordinalColors={this.ecoColors}
                    addFilterHandler={this.singleSelection}
                    fixedBarHeight={20}
                    onFiltered={(chart, filter) => {
                        this.setState({
                            testVariationFilterActive: chart.hasFilter(),
                        })
                    }}
                />
            if (this.state.testVariationFilterActive) {
                testVariationFilterReset =
                    <button onClick={() => dc.filterAll('testVariationChart')}>reset</button>
            }

        }
        return (
            <div className="container-fluid">
                <div className="card">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header">
                                <h4 className="title">ECO Transaction Volume by Date {dayFilterReset}</h4>
                            </div>
                            <div className="content">
                                {dayChart}
                                <div id="range-chart">
                                    {rangeChart}
                                </div>
                            </div>
                            <div className="footer text-right">
                                {dayFilterHint}
                                <hr/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="header">
                                <h4 className="title">Volume by Type {typeFilterReset}</h4>
                            </div>
                            <div className="content">
                                {typeChart}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="header">
                                <h4 className="title">Key Numbers</h4>
                            </div>
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-5 number-block">
                                        <small><small>Volume</small></small> {totalVolumeDisplay}
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 number-block">
                                        <small><small>CO2 Reduction</small></small> {totalCO2Display}
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-5 number-block">
                                        <small><small>Transactions</small></small> {totalCountDisplay}
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 number-block">
                                        <small><small>Users</small></small> {totalUsersDisplay}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="header">
                                <h4 className="title">Volume by Hour of Day {hourFilterReset}</h4>
                            </div>
                            <div className="content">
                                {hourChart}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="header">
                                <h4 className="title">Volume by Test Variation {testVariationFilterReset}</h4>
                            </div>
                            <div className="content">
                                {testVariationChart}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-md-3"}
                             style={authenticationService.isClient ? {display: 'none'} : {}}>
                            <div className="header">
                                <h4 className="title">Volume by Client {clientFilterReset}</h4>
                            </div>
                            <div className="content">
                                {clientChart}
                            </div>
                        </div>
                        <div className={authenticationService.isAdmin ? "col-md-3" : "col-md-4"}>
                            <div className="header">
                                <h4 className="title">Volume by Product {productFilterReset}</h4>
                            </div>
                            <div className="content">
                                {productChart}
                            </div>
                        </div>
                        <div className={authenticationService.isAdmin ? "col-md-3" : "col-md-4"}>
                            <div className="header">
                                <h4 className="title">Volume by User {userFilterReset}</h4>
                            </div>
                            <div className="content">
                                {userChart}
                            </div>
                        </div>
                        <div className={authenticationService.isAdmin ? "col-md-3" : "col-md-4"}>
                            <div className="header">
                                <h4 className="title">Volume by Team {teamFilterReset}</h4>
                            </div>
                            <div className="content">
                                {teamChart}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    singleSelection = (filters, filter) => [filter]

    volumeFormatter = (d) => d.key + ': ' + d.value.toFixed(0) + ' ECOs'

}

export default TransactionChart