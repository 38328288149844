import {authHeader, authHeaderOnly, handleResponse} from '../helpers'


export const earnsService = {
    list,
    get,
    update,
    insert,
    uploadImage,
    maxOrder,
    listCodes,
    addCode,
    removeCode,
    updateCode
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_LIST, requestOptions)
        .then(handleResponse)
}

function get(earnId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_GET + earnId, requestOptions)
        .then(handleResponse)
}

function update(earn) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(earn)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_UPDATE + earn.earnId, requestOptions)
        .then(handleResponse)
}

function insert(earn) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(earn)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_INSERT, requestOptions)
        .then(handleResponse)
}

function uploadImage(file) {

    // add file to FormData object
    const fd = new FormData();
    fd.append('file', file);

    // send `POST` request
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_UPLOADIMAGE, {
        method: 'POST',
        headers: authHeaderOnly(),
        body: fd
    })
        .then(handleResponse)
}

function maxOrder() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_EARNS_MAXORDER, requestOptions)
        .then(handleResponse)
}

function listCodes(earnId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_CODES_LIST + earnId + "/codes", requestOptions)
        .then(handleResponse)
}

function addCode(earnId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_CODES_LIST + earnId + "/codes/random", requestOptions)
        .then(handleResponse)
}

function removeCode(codeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_CODES_LIST + 'codes/' + codeId, requestOptions)
        .then(handleResponse)
}

function updateCode(code) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(code)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_CODES_LIST + 'codes/' + code.codeId, requestOptions)
        .then(handleResponse)
}