import React from "react";
import Checkbox from "../FormInputs/Checkbox";
import TextInput from "../FormInputs/TextInput";
import OpeningTimesForm from "./OpeningTimesForm";
import EmailDomainsEditor from "./EmailDomainsEditor";


function FormRow(props) {
    if (props.type === 'checkbox') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label"></label>
                <div className="col-md-9">
                    <Checkbox
                        input={
                            {
                                checked: props.value,
                                onChange: props.onChange,
                            }}
                        label={props.label}
                        type={props.type}
                        meta="error"
                    />
                </div>
            </div>
        )

    } else if (props.type === 'textarea') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <textarea
                        className="form-control"
                        value={props.value}
                        onChange={props.onChange}
                        label={props.label}
                        rows={props.rows}
                    />
                </div>
            </div>
        )

    } else if (props.type === 'select') {
        const options = props.options.map((option) =>
            <option key={option.value} value={option.value}>{option.text}</option>
        )
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <select className="form-control" value={props.value} onChange={props.onChange}>
                        {options}
                    </select>
                </div>
            </div>
        )

    } else if (props.type === 'file') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <input id={props.id} type="file" className="form-control-file"
                           onChange={props.onChange}/>
                </div>
            </div>
        )

    } else if (props.type === 'openingTimes') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <OpeningTimesForm onChange={props.onChange} value={props.value}/>
                </div>
            </div>
        )

    } else if(props.type === 'textList') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <EmailDomainsEditor onChange={props.onChange} value={props.value}/>
                </div>
            </div>
        )

    } else {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9">
                    <TextInput
                        input={
                            {
                                value: props.value,
                                onChange: props.onChange,
                                label: props.label
                            }}
                        type={props.type}
                        meta="error"
                    />
                </div>
            </div>
        )
    }
}

export default FormRow
