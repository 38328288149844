import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
import {connect} from 'react-redux';
import cx from 'classnames';
import {authenticationService} from "../../services/authentication.service";


class UserInfo extends Component {

    state = {
        isShowingUserMenu: false
    };

    render() {
        let {logout} = this.props;
        let {isShowingUserMenu} = this.state;
        return (
            <div className="user-wrapper">
                <div className="user">
                    <div className="userinfo">
                        <div className="username">
                          {getUsername()}
                        </div>
                        <div className="title">{getRole()} / {getId()}</div>
                    </div>
                    <span
                        onClick={() => this.setState({isShowingUserMenu: !this.state.isShowingUserMenu})}
                        className={cx("pe-7s-angle-down collapse-arrow", {
                            active: isShowingUserMenu
                        })}></span>
                </div>
                <Collapse in={isShowingUserMenu}>
                    <ul className="nav user-nav">
                        <li><a href="dummy">My Profile</a></li>
                        <li><a href="dummy">Edit Profile</a></li>
                        <li><a href="dummy" onClick={logout}>Logout</a></li>
                    </ul>
                </Collapse>
            </div>
        );
    }
}

const getUsername = () => {
    if (authenticationService.currentUserValue === null) {
        return "Not logged in"
    } else {
        return authenticationService.currentUserValue.nickname
    }
}

const getId = () => {
    if (authenticationService.currentUserValue === null) {
        return ""
    } else {
        return authenticationService.currentUserValue.userId
    }
}

const getRole = () => {
    if (authenticationService.currentUserValue === null) {
        return ""
    } else {
        return authenticationService.currentUserValue.role
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
});

export default connect(mapStateToProps)(UserInfo);