import React from 'react'
import {earnsService} from "../../services/earns.service";
import QRCode from 'qrcode.react'


class CodesEditor extends React.Component {

    state = {
        earnId: 0,
        codes: []
    }

    componentWillReceiveProps(props) {
        const {earnId} = props
        this.setState({
            earnId: earnId
        })
        this.updateCodes(earnId)
    }

    updateCodes = (earnId) => {
        earnsService.listCodes(earnId).then(codes => {
            console.log("Loaded codes", codes)
            this.setState({codes: codes.codes})
        })
    }

    render() {
        return (
            <div>
                {this.state.codes
                    .filter(code => code.active)
                    .map(code =>
                        <div className="row" style={{marginTop: 5 + 'px', marginBottom: 10 + 'px'}} key={code.codeId}>
                            <div>
                                <div className="col-xs-6">
                                    {console.log("Code", code)}
                                    <QRCode value={'ecocoin-earn:' + code.code}/>
                                    <div style={{fontSize: 20 + 'px'}}>
                                        {code.code}
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <button className="btn" onClick={() => this.onDisable(code)}>Disable</button>
                                </div>
                            </div>
                        </div>
                    )}
                <button className="btn btn-info btn-fill btn-wd" onClick={this.onAdd}>Add</button>
                <h4>Disabled Codes</h4>
                {this.state.codes
                    .filter(code => !code.active)
                    .map(code =>
                        <div className="row" style={{marginTop: 5 + 'px', marginBottom: 10 + 'px'}} key={code.codeId}>
                            <div>
                                <div className="col-xs-6">
                                    {console.log("Code", code)}
                                    <div style={{fontSize: 20 + 'px'}}>
                                        {code.code}
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <button className="btn" onClick={() => this.onEnable(code)}>Enable</button>
                                    <button className="btn" onClick={() => this.onRemove(code.codeId)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        )
    }

    onAdd = () => {
        console.log("Add code ", this.state.earnId)
        earnsService.addCode(this.state.earnId).then(() => {
            this.updateCodes(this.state.earnId)
        })
    }

    onRemove = (codeId) => {
        console.log("Remove code", codeId)
        earnsService.removeCode(codeId).then(() => {
            this.updateCodes(this.state.earnId)
        })
    }

    onDisable = (code) => {
        console.log("Disable code", code)
        code.active = false
        this.updateCode(code)
    }

    onEnable = (code) => {
        console.log("Enable code", code)
        code.active = true
        this.updateCode(code)
    }

    updateCode = (code) => {
        earnsService.updateCode(code).then(() => {
            this.updateCodes(this.state.earnId)
        })
    }
}

export default CodesEditor
