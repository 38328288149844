import React from 'react'
import FormRow from "../../components/ecocoin/FormRow";
import {earnsService} from "../../services/earns.service";
import PageTitle from "../../components/ecocoin/PageTitle";
import PageFooter from "../../components/ecocoin/PageFooter";
import ViewRow from "../../components/ecocoin/ViewRow";
import {authenticationService} from "../../services/authentication.service";


class EarnAdd extends React.Component {

    state = {
        clone: false,
        earn: {
            nameEn: '',
            nameNl: '',
            subtitleEn: '',
            subtitleNl: '',
            locationEn: '',
            locationNl: '',
            descriptionEn: '',
            descriptionNl: '',
            tagsEn: '',
            tagsNl: '',
            co2ReductionDescEn: '',
            co2ReductionDescNl: '',
            clientId: 1,
            co2ReductionAmount: 0,
            amount: 0,
            active: true,
            imageUrl: '',
            usageRestriction: 'none',
            earnDates: [],
            orderNo: 0
        }
    }

    restrictionOptions = [
        {value: "none", text: "None"},
        {value: "twicePerDay", text: "Twice per day"},
        {value: "daily", text: "Daily"},
        {value: "weekly", text: "Weekly"},
        {value: "once", text: "Only once"}
    ]

    componentWillMount() {

        if(this.props.location.state !== undefined) {
            console.log("Location props", this.props.location.state)
            this.setState({
                earn: this.props.location.state.earn
            })
            this.setState({
                clone: true
            })
        }

        earnsService.maxOrder().then(response => {
            console.log("Max order", response)
            this.setState({
                earn: {
                    ...this.state.earn,
                    orderNo: response.order_no + 10
                }
            })
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle title="Add Earn"/>
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h4>English</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Name" type="text" value={this.state.earn.nameEn}
                                             onChange={this.setNameEn}/>
                                    <FormRow label="Subtitle" type="text" value={this.state.earn.subtitleEn}
                                             onChange={this.setSubtitleEn}/>
                                    <FormRow label="Location" type="text" value={this.state.earn.locationEn}
                                             onChange={this.setLocationEn}/>
                                    <FormRow label="Description (HTML)" type="textarea"
                                             value={this.state.earn.descriptionEn} onChange={this.setDescriptionEn}
                                             rows="15"/>
                                    <FormRow label="Tags" type="text" value={this.state.earn.tagsEn}
                                             onChange={this.setTagsEn}/>
                                    <FormRow label="CO2e reduction description" type="textarea"
                                             value={this.state.earn.co2ReductionDescEn}
                                             onChange={this.setCo2ReductionDescEn} rows="3"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h4>Dutch</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Name" type="text" value={this.state.earn.nameNl}
                                             onChange={this.setNameNl}/>
                                    <FormRow label="Subtitle" type="text" value={this.state.earn.subtitleNl}
                                             onChange={this.setSubtitleNl}/>
                                    <FormRow label="Location" type="text" value={this.state.earn.locationNl}
                                             onChange={this.setLocationNl}/>
                                    <FormRow label="Description (HTML)" type="textarea"
                                             value={this.state.earn.descriptionNl} onChange={this.setDescriptionNl}
                                             rows="15"/>
                                    <FormRow label="Tags" type="text" value={this.state.earn.tagsNl}
                                             onChange={this.setTagsNl}/>
                                    <FormRow label="CO2e reduction description" type="textarea"
                                             value={this.state.earn.co2ReductionDescNl}
                                             onChange={this.setCo2ReductionDescNl} rows="3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Earn amount (ECOs)" type="number"
                                             value={this.state.earn.amount / process.env.REACT_APP_ECOCOIN_DIVIDER}
                                             onChange={this.setAmount}/>
                                    <FormRow label="CO2e reduction amount (gramms)" type="number"
                                             value={this.state.earn.co2ReductionAmount}
                                             onChange={this.setCo2ReductionAmount}/>
                                    <FormRow label="Usage restriction" type="select"
                                             options={this.restrictionOptions}
                                             value={this.state.earn.usageRestriction}
                                             onChange={this.setUsageRestriction}/>
                                    <FormRow label="Active" type="checkbox" value={this.state.earn.active}
                                             onChange={this.setActive}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <FormRow label="Client ID" type="number" value={this.state.earn.clientId}
                                                 onChange={this.setClientId}/>
                                    </div>
                                    <FormRow label="Order No." type="number" value={this.state.earn.orderNo}
                                             onChange={this.setOrderNo}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h4>Image</h4>
                                </div>
                                <div className="content">
                                    <ViewRow type="image-wide"
                                             value={process.env.REACT_APP_IMAGE_BASE + this.state.earn.imageUrl}/>
                                    <FormRow id="imageUrlField" label="Earn image" type="file"
                                             value={this.state.earn.imageUrl}
                                             onChange={this.setImageUrl}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>Opening Times</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Opening times" type="openingTimes" value={this.state.earn.earnDates}
                                             onChange={this.setEarnDates}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter buttonText="Add" cancelText="Cancel" onCancelClick={this.cancel}/>
                </form>
            </div>

        )
    }

    setNameEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                nameEn: e.target.value
            }
        })
    }

    setSubtitleEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                subtitleEn: e.target.value
            }
        })
    }

    setLocationEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                locationEn: e.target.value
            }
        })
    }

    setDescriptionEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                descriptionEn: e.target.value
            }
        })
    }

    setTagsEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                tagsEn: e.target.value
            }
        })
    }

    setCo2ReductionDescEn = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                co2ReductionDescEn: e.target.value
            }
        })
    }

    setNameNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                nameNl: e.target.value
            }
        })
    }

    setSubtitleNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                subtitleNl: e.target.value
            }
        })
    }

    setLocationNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                locationNl: e.target.value
            }
        })
    }

    setDescriptionNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                descriptionNl: e.target.value
            }
        })
    }

    setTagsNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                tagsNl: e.target.value
            }
        })
    }

    setCo2ReductionDescNl = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                co2ReductionDescNl: e.target.value
            }
        })
    }

    setClientId = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                clientId: e.target.value
            }
        })
    }

    setOrderNo = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                orderNo: e.target.value
            }
        })
    }

    setCo2ReductionAmount = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                co2ReductionAmount: e.target.value
            }
        })
    }

    setAmount = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                amount: e.target.value * process.env.REACT_APP_ECOCOIN_DIVIDER
            }
        })
    }

    setActive = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                active: e.target.checked
            }
        })
    }

    setUsageRestriction = (e) => {
        this.setState({
            earn: {
                ...this.state.earn,
                usageRestriction: e.target.value
            }
        })
    }

    setImageUrl = (e) => {

        // select file input
        const input = document.getElementById('imageUrlField')
        earnsService.uploadImage(input.files[0])
            .then(result => {
                this.setState({
                    earn: {
                        ...this.state.earn,
                        imageUrl: result.filename
                    }
                })
                console.log("set image url: ", result.filename)
            })
    }

    setEarnDates = (e) => {
        console.log("Set earn dates", e)
        this.setState({
            earn: {
                ...this.state.earn,
                earnDates: e
            }
        })
    }

    cancel = e => {
        e.preventDefault()
        window.history.back()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("submit", this.state.earn)
        earnsService.insert(this.state.earn)
            .then(e => {
                window.history.back()
                if(this.state.clone) {
                    window.history.back()
                }
            })
    }
}

export default EarnAdd
