import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {authenticationService} from "../../services/authentication.service";
import {withRouter} from "react-router";
import PageTitle from "../../components/ecocoin/PageTitle";
import {CSVLink} from "react-csv";
import UserBatchUpdate from "./UserBatchUpdate";
import {boolTypes, roleName, roleTypes} from "../../helpers/data";
import ActiveFormatter from "../../components/ActiveFormatter";


class Users extends Component {

    state = {
        users: [],
        batchUpdate: false,
        selected: []
    }

    componentWillMount() {
        this.loadUsers()
    }

    render() {
        const {users} = this.state;
        const options = {
            sizePerPage: 25,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: false,
            onRowClick: function (row, columnindex, rowIndex, e) {
                console.log("ROW CLICK", e)
                window.location = "/#/users/" + row.userId
            },
            defaultSortName: 'userId',
            defaultSortOrder: 'desc'
        }
        const selectRowProp = {
            mode: 'checkbox'
        }

        return (
            <div className="container-fluid">
                <PageTitle title="All Users" buttonText="Add" onButtonClick={this.addUser}/>
                {this.state.batchUpdate ? (
                    <UserBatchUpdate
                        selected={this.state.selected}
                        onCancel={this.batchCancel}
                        onUpdated={this.onBatchUpdated}
                    />
                ) : ('')}
                <div className="row" style={{display: this.state.batchUpdate ? 'none' : 'block'}}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={users}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}
                                    selectRow={selectRowProp}
                                    ref='table'
                                >
                                    <TableHeaderColumn
                                        dataField='userId'
                                        isKey
                                        width="6%"
                                        dataSort
                                        dataAlign="right">
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='username'
                                        width="30%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Email
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='nickname'
                                        width="20%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Nickname
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='roleId'
                                        width="10%"
                                        filter={{type: 'SelectFilter', options: roleTypes}}
                                        dataFormat={roleId => roleName(roleId)}
                                        dataSort>
                                        Role
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='clientId'
                                        width={authenticationService.isAdmin ? "20%" : "0%"}
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator: '='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Client
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='teamId'
                                        width="20%"
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator: '='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Team
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='active'
                                        width="10%"
                                        dataSort
                                        dataAlign="center"
                                        filter={{type: 'SelectFilter', options: boolTypes}}
                                        dataFormat={this.activeFormatter}>
                                        Active
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='testVariation'
                                        width={authenticationService.isAdmin ? "10%" : "0%"}
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Variation
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                        <CSVLink className="btn btn-wd" filename={"ecocoin-users.csv"} data={users}>
                            Download CSV
                        </CSVLink>&nbsp;
                        <div style={authenticationService.isClient ? {display: "none"} : {}}>
                            <button className="btn btn-wd" onClick={this.batchUpdate.bind(this)}>
                                Update selected users
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    activeFormatter = (cell, row) => {
        return (
            <ActiveFormatter active={cell}/>
        );
    }

    loadUsers = () => {
        authenticationService.list()
            .then(users => {
                this.setState({
                    users: users
                })
            })
    }

    addUser = () => {
        window.location = "/#/users/add"
    }

    batchUpdate = () => {
        console.log(this.refs.table.state.selectedRowKeys)
        this.setState({
            ...this.state,
            batchUpdate: true,
            selected: this.refs.table.state.selectedRowKeys
        })
    }

    batchCancel = () => {
        this.setState({
            ...this.state,
            batchUpdate: false
        })
    }

    onBatchUpdated = () => {
        this.loadUsers()
        this.setState({
            ...this.state,
            batchUpdate: false
        })
    }
}

export default withRouter(Users)