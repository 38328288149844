import {BehaviorSubject} from 'rxjs'
import {authHeader, handleResponse, plainHeader} from '../helpers'


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

export const authenticationService = {
    login,
    logout,
    list,
    get,
    update,
    insert,
    listTeams,
    getTeam,
    updateTeam,
    insertTeam,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    get isAdmin() {
        return currentUserSubject.value.role === 'ADMIN'
    },
    get isClient() {
        return currentUserSubject.value.role === 'CLIENT'
    }
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({username, password})
    }

    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_USERS_AUTHENTICATE, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            setCurrentUser(user)
            currentUserSubject.next(user)
            console.log("DONE REQUEST LOGIN", user)
            return user
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')
    currentUserSubject.next(null)
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_USERS_LIST, requestOptions)
        .then(handleResponse)
}

function get(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_USERS_GET + userId, requestOptions)
        .then(handleResponse)
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_USERS_UPDATE + user.userId, requestOptions)
        .then(handleResponse)
}

function insert(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_USERS_INSERT, requestOptions)
        .then(handleResponse)
}

function listTeams() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TEAMS_LIST, requestOptions)
        .then(handleResponse)
}

function getTeam(teamId) {
    return listTeams().then(teams => {
        return teams.filter(team => {
            return team.teamId === Number(teamId)
        })[0]
    })
}

function updateTeam(team) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(team)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TEAMS_UPDATE + team.teamId, requestOptions)
        .then(handleResponse)
}

function insertTeam(team) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(team)
    }
    return fetch(process.env.REACT_APP_BACKEND_BASE + process.env.REACT_APP_TEAMS_INSERT, requestOptions)
        .then(handleResponse)
}

// Private
function setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user))
}