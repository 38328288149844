import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {authenticationService} from "../../services/authentication.service";
import {withRouter} from "react-router";
import PageTitle from "../../components/ecocoin/PageTitle";


class Teams extends Component {

    state = {
        teams: []
    }

    componentWillMount() {
        authenticationService.listTeams()
            .then(teams => {
                this.setState({
                    teams: teams
                })
            })
    }

    render() {
        const {teams} = this.state;
        const options = {
            sizePerPage: 20,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: true,
            onRowClick: function(row, columnindex, rowIndex, e) {
                console.log("ROW CLICK", e)
                window.location = "/#/teams/" + row.teamId
            }
        }

        return (
            <div className="container-fluid">
                <PageTitle title="All Teams" buttonText="Add" onButtonClick={this.addTeam} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={teams}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}>
                                    <TableHeaderColumn
                                        dataField='teamId'
                                        isKey
                                        width="5%"
                                        dataSort>
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='name'
                                        width="10%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Team name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='clientId'
                                        width={authenticationService.isAdmin ? "10%" : "0%"}
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Client
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addTeam = () => {
        window.location = "/#/teams/add"
    }
}

export default withRouter(Teams)