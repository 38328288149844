import React from 'react';
import {connect} from 'react-redux';
import {toggleMobileNavVisibility} from '../../reducers/Layout';
import {Navbar} from 'react-bootstrap';

const Header = ({
                    showMobileMenu,
                    toggleMobileNavVisibility,
                    logout
                }) => (
    <div>
        <Navbar className="visible-xs visible-sm" fluid={true}>
            <Navbar.Header>
                <button type="button" className="navbar-toggle" data-toggle="collapse"
                        onClick={toggleMobileNavVisibility}>
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
            </Navbar.Header>
        </Navbar>
    </div>
);

const mapDispatchToProp = dispatch => ({
    toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility())
});

export default connect(null, mapDispatchToProp)(Header);