export const roleOptions = [
    {value: 2, text: "User"},
    {value: 3, text: "Client"},
    {value: 1, text: "Admin"}
]

export const roleTypes = {
    1: 'Admin',
    2: 'User',
    3: 'Client'
}

export function roleName(roleId) {
    if(roleId === undefined) {
        return ''
    }
    return roleOptions.find(role => role.value === roleId).text;
}

export const boolTypes = {
    true: 'Yes',
    false: 'No'
}

export function boolName(active) {
    if(active) return 'Yes'
    return 'No'
}

export const transactionTypes = {
    'earn': 'earn',
    'spend': 'spend',
    'deposit': 'deposit',
    'withdraw': 'withdraw'
}

export const timezoneOptions = [
    {value: "Europe/Amsterdam", text: "Europe/Amsterdam"},
    {value: "Europe/Andorra", text: "Europe/Andorra"},
    {value: "Europe/Astrakhan", text: "Europe/Astrakhan"},
    {value: "Europe/Athens", text: "Europe/Athens"},
    {value: "Europe/Belfast", text: "Europe/Belfast"},
    {value: "Europe/Belgrade", text: "Europe/Belgrade"},
    {value: "Europe/Berlin", text: "Europe/Berlin"},
    {value: "Europe/Bratislava", text: "Europe/Bratislava"},
    {value: "Europe/Brussels", text: "Europe/Brussels"},
    {value: "Europe/Bucharest", text: "Europe/Bucharest"},
    {value: "Europe/Budapest", text: "Europe/Budapest"},
    {value: "Europe/Busingen", text: "Europe/Busingen"},
    {value: "Europe/Chisinau", text: "Europe/Chisinau"},
    {value: "Europe/Copenhagen", text: "Europe/Copenhagen"},
    {value: "Europe/Dublin", text: "Europe/Dublin"},
    {value: "Europe/Gibraltar", text: "Europe/Gibraltar"},
    {value: "Europe/Guernsey", text: "Europe/Guernsey"},
    {value: "Europe/Helsinki", text: "Europe/Helsinki"},
    {value: "Europe/Isle_of_Man", text: "Europe/Isle_of_Man"},
    {value: "Europe/Istanbul", text: "Europe/Istanbul"},
    {value: "Europe/Jersey", text: "Europe/Jersey"},
    {value: "Europe/Kaliningrad", text: "Europe/Kaliningrad"},
    {value: "Europe/Kiev", text: "Europe/Kiev"},
    {value: "Europe/Kirov", text: "Europe/Kirov"},
    {value: "Europe/Lisbon", text: "Europe/Lisbon"},
    {value: "Europe/Ljubljana", text: "Europe/Ljubljana"},
    {value: "Europe/London", text: "Europe/London"},
    {value: "Europe/Luxembourg", text: "Europe/Luxembourg"},
    {value: "Europe/Madrid", text: "Europe/Madrid"},
    {value: "Europe/Malta", text: "Europe/Malta"},
    {value: "Europe/Mariehamn", text: "Europe/Mariehamn"},
    {value: "Europe/Minsk", text: "Europe/Minsk"},
    {value: "Europe/Monaco", text: "Europe/Monaco"},
    {value: "Europe/Moscow", text: "Europe/Moscow"},
    {value: "Europe/Nicosia", text: "Europe/Nicosia"},
    {value: "Europe/Oslo", text: "Europe/Oslo"},
    {value: "Europe/Paris", text: "Europe/Paris"},
    {value: "Europe/Podgorica", text: "Europe/Podgorica"},
    {value: "Europe/Prague", text: "Europe/Prague"},
    {value: "Europe/Riga", text: "Europe/Riga"},
    {value: "Europe/Rome", text: "Europe/Rome"},
    {value: "Europe/Samara", text: "Europe/Samara"},
    {value: "Europe/San_Marino", text: "Europe/San_Marino"},
    {value: "Europe/Sarajevo", text: "Europe/Sarajevo"},
    {value: "Europe/Saratov", text: "Europe/Saratov"},
    {value: "Europe/Simferopol", text: "Europe/Simferopol"},
    {value: "Europe/Skopje", text: "Europe/Skopje"},
    {value: "Europe/Sofia", text: "Europe/Sofia"},
    {value: "Europe/Stockholm", text: "Europe/Stockholm"},
    {value: "Europe/Tallinn", text: "Europe/Tallinn"},
    {value: "Europe/Tirane", text: "Europe/Tirane"},
    {value: "Europe/Tiraspol", text: "Europe/Tiraspol"},
    {value: "Europe/Ulyanovsk", text: "Europe/Ulyanovsk"},
    {value: "Europe/Uzhgorod", text: "Europe/Uzhgorod"},
    {value: "Europe/Vaduz", text: "Europe/Vaduz"},
    {value: "Europe/Vatican", text: "Europe/Vatican"},
    {value: "Europe/Vienna", text: "Europe/Vienna"},
    {value: "Europe/Vilnius", text: "Europe/Vilnius"},
    {value: "Europe/Volgograd", text: "Europe/Volgograd"},
    {value: "Europe/Warsaw", text: "Europe/Warsaw"},
    {value: "Europe/Zagreb", text: "Europe/Zagreb"},
    {value: "Europe/Zaporozhye", text: "Europe/Zaporozhye"},
    {value: "Europe/Zurich", text: "Europe/Zurich"}
]