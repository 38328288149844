import React from 'react'
import ViewRow from "../../components/ecocoin/ViewRow"
import {spendsService} from "../../services/spends.service"
import PageTitle from "../../components/ecocoin/PageTitle"
import {withRouter} from "react-router-dom";
import {authenticationService} from "../../services/authentication.service";


class SpendView extends React.Component {

    state = {
        spend: {
            nameEn: '',
            nameNl: '',
            locationEn: '',
            locationNl: '',
            descriptionEn: '',
            descriptionNl: '',
            clientId: 1,
            voucher: '',
            amount: 0,
            active: true,
            imageUrl: '',
            totalAvailable: 0,
            totalUsagePerUser: 0,
            usage: 0,
            orderNo: 0
        }
    }

    componentWillMount() {
        spendsService.get(this.props.match.params.id).then(spend => {
            console.log("Loaded apend", spend)
            this.setState({spend: spend})
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle
                    title={this.state.spend.nameEn}
                    buttonText="Edit"
                    onButtonClick={this.gotoEdit}
                    button2Text="Clone"
                    onButton2Click={this.clone}
                    withBackButton="true"
                    onBackButtonClick={this.goBack}
                    subTitle={"SPEND-" + this.state.spend.spendId}
                />

                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>English</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Name" value={this.state.spend.nameEn}/>
                                    <ViewRow label="Location" value={this.state.spend.locationEn}/>
                                    <ViewRow label="Description" type="html" value={this.state.spend.descriptionEn}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>Dutch</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Name" value={this.state.spend.nameNl}/>
                                    <ViewRow label="Location" value={this.state.spend.locationNl}/>
                                    <ViewRow label="Description" type="html" value={this.state.spend.descriptionNl}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="content">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="form-horizontal">
                                    <ViewRow label="Price (ECOs)"
                                             value={this.state.spend.amount / process.env.REACT_APP_ECOCOIN_DIVIDER}/>
                                    <ViewRow label="Voucher" value={this.state.spend.voucher}/>
                                    <ViewRow label="Active" value={this.state.spend.active ? "yes" : "no"}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <ViewRow label="Client ID" value={this.state.spend.clientId}/>
                                    </div>
                                    <ViewRow label="Order No." value={this.state.spend.orderNo}/>
                                    <ViewRow label="Total available" value={this.state.spend.totalAvailable}/>
                                    <ViewRow label="Total usage per user" value={this.state.spend.totalUsagePerUser}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="header">
                                <h4>Image</h4>
                            </div>
                            <div className="content">
                                <ViewRow type="image-wide"
                                         value={process.env.REACT_APP_IMAGE_BASE + this.state.spend.imageUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoEdit = () => {
        window.location = "/#/spends/" + this.props.match.params.id + "/edit"
    }

    clone = () => {
        this.props.history.push({
            pathname: '/spends/add',
            state: this.state
        })
    }

    goBack = e => {
        e.preventDefault()
        window.history.back()
    }
}

export default withRouter(SpendView)

