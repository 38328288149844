import React from 'react'
import {authenticationService} from "../../services/authentication.service";
import PageFooter from "../../components/ecocoin/PageFooter";
import {roleOptions} from "../../helpers/data";
import FormRowWithCheckbox from "../../components/ecocoin/FormRowWithCheckbox";


class UserBatchUpdate extends React.Component {

    state = {
        user: {
            active: false,
            clientId: '',
            teamId: '',
            roleId: 2,
            testVariation: '',
            updateActive: false,
            updateClientId: false,
            updateTeamId: false,
            updateRoleId: false,
            updateTestVariation: false
        }
    }
    render() {
        const { selected } = this.props
        return (
            <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <h4>Batch Update <small>{selected.length} users selected</small></h4>
                            </div>
                            <div className="content">
                                <FormRowWithCheckbox
                                    label="Active"
                                    type="checkbox"
                                    value={this.state.user.active}
                                    onChange={this.setActive}
                                    checkLabel="Update"
                                    checked={this.state.user.updateActive}
                                    onCheckChange={this.setUpdateActive}
                                />
                                <FormRowWithCheckbox
                                    label="Client ID"
                                    type="number"
                                    value={this.state.user.clientId}
                                    onChange={this.setClientId}
                                    checkLabel="Update"
                                    checked={this.state.user.updateClientId}
                                    onCheckChange={this.setUpdateClientId}
                                />
                                <FormRowWithCheckbox
                                    label="Team ID"
                                    type="number"
                                    value={this.state.user.teamId}
                                    onChange={this.setTeamId}
                                    checkLabel="Update"
                                    checked={this.state.user.updateTeamId}
                                    onCheckChange={this.setUpdateTeamId}
                                />
                                <FormRowWithCheckbox
                                    label="Role"
                                    type="select"
                                    options={roleOptions}
                                    value={this.state.user.roleId}
                                    onChange={this.setRoleId}
                                    checkLabel="Update"
                                    checked={this.state.user.updateRoleId}
                                    onCheckChange={this.setUpdateRoleId}
                                />
                                <FormRowWithCheckbox
                                    label="Test variation"
                                    type="text"
                                    value={this.state.user.testVariation}
                                    onChange={this.setTestVariation}
                                    checkLabel="Update"
                                    checked={this.state.user.updateTestVariation}
                                    onCheckChange={this.setUpdateTestVariation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <PageFooter buttonText={"Update "+this.props.selected.length+" users"} cancelText="Cancel" onCancelClick={this.onCancel}/>
            </form>
        )
    }

    setActive = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                active: e.target.checked,
                updateActive: true
            }
        })
    }

    setClientId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                clientId: e.target.value,
                updateClientId: true
            }
        })
    }

    setTeamId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                teamId: e.target.value,
                updateTeamId: true
            }
        })
    }

    setRoleId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                roleId: parseInt(e.target.value, 10),
                updateRoleId: true
            }
        })
    }

    setTestVariation = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                testVariation: e.target.value,
                updateTestVariation: true
            }
        })
    }

    setUpdateActive = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                updateActive: e.target.checked
            }
        })
    }

    setUpdateClientId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                updateClientId: e.target.checked
            }
        })
    }

    setUpdateTeamId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                updateTeamId: e.target.checked
            }
        })
    }

    setUpdateRoleId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                updateRoleId: e.target.checked
            }
        })
    }

    setUpdateTestVariation = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                updateTestVariation: e.target.checked
            }
        })
    }

    onCancel = e => {
        e.preventDefault()
        this.props.onCancel()
        e.preventDefault()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("Batch update", this.props.selected)
        var runit = Promise.resolve()
        this.props.selected.forEach((userId) => {
            console.log("Update user ", userId)
            runit = runit
                .then(() => authenticationService.get(userId))
                .then(user => this.updateUserRecord(user))
                .then(user => authenticationService.update(user))
        })
        runit.then(() => {
            console.log("DONE WITH EVERYTHING")
            this.props.onUpdated()
        })

        e.preventDefault()
    }

    updateUserRecord = (userOrig) => {
        let user = {
            ...userOrig
        }
        if(this.state.user.updateActive) {
            user.active = this.state.user.active
        }
        if(this.state.user.updateClientId) {
            user.clientId = this.state.user.clientId
        }
        if(this.state.user.updateTeamId) {
            user.teamId = this.state.user.teamId
        }
        if(this.state.user.updateRoleId) {
            user.roleId = this.state.user.roleId
        }
        if(this.state.user.updateTestVariation) {
            user.testVariation = this.state.user.testVariation
        }
        console.log("Applied updates: ", user)
        return user
    }

}

export default UserBatchUpdate