import React from 'react'


class EmailDomainsEditor extends React.Component {

    state = {
        domains: ['Hallo', 'Welt']
    }

    constructor(props) {
        super(props)
        this.state = {
            domains: props.value
        }
    }

    render() {
        console.log("Render emails domains", this.props)
        return (
            <div>
                {this.state.domains.map((domain, index) =>
                    <div className="form-row">
                        <div className="col-md-8">
                            <input
                                className="form-control"
                                id={index}
                                value={domain}
                                onChange={(e) => this.onChangeDomain(index, e)}
                            />
                        </div>
                        <div className="col">
                            <button
                                className="btn btn-info"
                                onClick={(e) => this.removeDomain(index, e)}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                )}
                <button className="btn btn-info" onClick={this.addDomain}>Add</button>
            </div>
        )
    }

    onChangeDomain = (index, e) => {
        const domains = this.state.domains.slice()
        domains[index] = e.target.value
        this.setState({
            domains: domains
        })
        this.props.onChange(domains)
    }

    addDomain = (e) => {
        const domains = this.state.domains.slice()
        domains.push('')
        this.setState({
            domains: domains
        })
        this.props.onChange(domains)
        e.preventDefault()
    }

    removeDomain = (index, e) => {
        const domains = this.state.domains.slice()
        domains.splice(index, 1)
        this.setState({
            domains: domains
        })
        this.props.onChange(domains)
        e.preventDefault()
    }

}

export default EmailDomainsEditor
