import React from 'react'
import PageTitle from "../../components/ecocoin/PageTitle"
import {transactionsService} from "../../services/transactions.service"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {CSVLink} from "react-csv"


class AllBalances extends React.Component {

    state = {
        transactions: [],
        balances: []
    }

    componentWillMount() {
        transactionsService.list()
            .then(this.transformTransactions)
            .then(balances => {
                this.setState({
                    balances: balances
                })
            })
    }

    transformTransactions = (transactions) => {
        const balances = {}
        transactions.forEach((transaction) => {
            if(!(transaction.creditorId in balances)) {
                balances[transaction.creditorId] = {balance: 0, userId: transaction.creditorId}
            }
            balances[transaction.creditorId].balance += transaction.amount / process.env.REACT_APP_ECOCOIN_DIVIDER;
            if(!(transaction.debitorId in balances)) {
                balances[transaction.debitorId] = {balance: 0, userId: transaction.debitorId}
            }
            balances[transaction.debitorId].balance -= transaction.amount / process.env.REACT_APP_ECOCOIN_DIVIDER;
        })
        const barray = []
        for(var key in balances) {
            barray.push(balances[key])
        }
        return barray
    }

    render() {
        const {balances} = this.state;
        const options = {
            sizePerPage: 20,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: true,
            defaultSortName: 'userId',
            defaultSortOrder: 'asc'
        }

        console.log("balances for table", balances)
        return (
            <div className="container-fluid">
                <PageTitle title="All Balances"/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={balances}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}>
                                    <TableHeaderColumn
                                        dataField='userId'
                                        isKey
                                        width="5%"
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        User ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='balance'
                                        width="5%"
                                        filter={{
                                            type: 'NumberFilter',
                                            numberComparators: ['=', '>=', '<'],
                                            defaultValue: {comparator: '>='}
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Balance (ECOs)
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                        <CSVLink className="btn btn-wd" filename={"ecocoin-balances.csv"} data={balances}>
                            Download CSV
                        </CSVLink>
                    </div>
                </div>
            </div>
        )
    }

}

export default AllBalances
