import React from 'react'
import {authenticationService} from "../../services/authentication.service";
import ViewRow from "../../components/ecocoin/ViewRow";
import PageTitle from "../../components/ecocoin/PageTitle";


class TeamView extends React.Component {

    state = {
        team: {}
    }

    componentWillMount() {
        authenticationService.getTeam(this.props.match.params.id).then(team => {
            this.setState({team: team})
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <PageTitle title={this.state.team.name} buttonText="Edit" onButtonClick={this.gotoEdit} withBackButton="true" onBackButtonClick={this.goBack} subTitle={"TEAM-"+this.state.team.teamId}/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <h4>General</h4>
                            </div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <ViewRow label="Team name" value={this.state.team.name}/>
                                    <ViewRow label="Team ID" value={this.state.team.teamId}/>
                                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                                        <ViewRow label="Client ID" value={this.state.team.clientId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoEdit = () => {
        window.location="/#/teams/"+this.props.match.params.id+"/edit"
    }

    goBack = e => {
        e.preventDefault()
        window.history.back()
    }
}

export default TeamView

