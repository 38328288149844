import React from 'react'
import PageTitle from "../../components/ecocoin/PageTitle"
import {transactionsService} from "../../services/transactions.service"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {CSVLink} from "react-csv"
import {transactionTypes} from "../../helpers/data";
import {authenticationService} from "../../services/authentication.service";


class Transactions extends React.Component {

    state = {
        transactions: []
    }

    componentWillMount() {
        transactionsService.list()
            .then(transactions => transactions.map(transaction => {
                transaction.amount /= process.env.REACT_APP_ECOCOIN_DIVIDER
                transaction.co2ReductionAmount /= 1000.0
                return transaction
            }))
            .then(transactions => {
                this.setState({
                    transactions: transactions
                })
            })
    }

    render() {
        const {transactions} = this.state;
        const options = {
            sizePerPage: 20,
            prePage: 'Previous',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            hideSizePerPage: true,
            defaultSortName: 'transactionId',
            defaultSortOrder: 'desc'
        }

        const csvData = []
        transactions.forEach(tx => {
            if (tx.type === 'earn') {
                tx.clientId = tx.debitorId
                tx.userId = tx.creditorId
                tx.amountNorm = tx.amount
            } else if (tx.type === 'spend') {
                tx.clientId = tx.creditorId
                tx.userId = tx.debitorId
                tx.amountNorm = -tx.amount
            } else if (tx.type === 'deposit') {
                tx.clientId = ''
                tx.userId = tx.creditorId
                tx.amountNorm = tx.amount
            } else if (tx.type === 'withdraw') {
                tx.clientId = ''
                tx.userId = tx.debitorId
                tx.amountNorm = -tx.amount
            }
            csvData.push({
                id: tx.transactionId,
                time: tx.createdAt,
                type: tx.type,
                name: tx.name,
                amount: tx.amount,
                co2ReductionAmount: tx.co2ReductionAmount,
                creditor: tx.creditorId,
                debitor: tx.debitorId,
                testVariation: tx.testVariation,
                teamId: tx.teamId
            })
        })

        return (
            <div className="container-fluid">
                <PageTitle title="All Transactions" buttonText="Add" onButtonClick={this.addTransaction}/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                                <BootstrapTable
                                    data={transactions}
                                    bordered={false}
                                    striped
                                    hover
                                    pagination={true}
                                    options={options}>
                                    <TableHeaderColumn
                                        dataField='transactionId'
                                        isKey
                                        width="3%"
                                        dataSort
                                        dataAlign="right">
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='createdAt'
                                        width="7%"
                                        dataSort
                                        filter={{
                                            type: 'DateFilter',
                                            dateComparators: ['=', '>=', '<'],
                                            defaultValue: {comparator: '='}
                                        }}>
                                        Time
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='type'
                                        width="5%"
                                        filter={{type: 'SelectFilter', options: transactionTypes}}
                                        dataSort>
                                        Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='name'
                                        width="10%"
                                        filter={{type: 'TextFilter'}}
                                        dataSort>
                                        Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='amountNorm'
                                        width="5%"
                                        dataSort
                                        dataAlign="right">
                                        Amount (ECOs)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='co2ReductionAmount'
                                        width="5%"
                                        dataSort
                                        dataAlign="right">
                                        CO2 Reduction (kg)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='userId'
                                        width="5%"
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        User ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='clientId'
                                        width={authenticationService.isAdmin ? "5%" : "0%"}
                                        filter={{
                                            type: 'NumberFilter',
                                            defaultValue: {comparator:'='},
                                            numberComparators: ['=']
                                        }}
                                        dataSort
                                        dataAlign="right">
                                        Client ID
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                        <CSVLink className="btn btn-wd" filename={"ecocoin-transactions.csv"} data={csvData}>
                            Download CSV
                        </CSVLink>
                    </div>
                </div>
            </div>
        )
    }

    addTransaction = () => {
        window.location = "/#/transactions/add"
    }

}

export default Transactions
