import React from "react";

function PageFooter(props) {
    let button = <span/>
    if (props.onButtonClick) {
        button =
            <button className="btn btn-info btn-fill btn-wd" onClick={props.onButtonClick}>{props.buttonText.toUpperCase()}</button>
    } else {
        button = <button type="submit" className="btn btn-info btn-fill btn-wd">{props.buttonText.toUpperCase()}</button>
    }
    let cancel = <span/>
    if (props.cancelText) {
        cancel =
            <span>
                &nbsp;&nbsp;&nbsp;
                <a href="/cancel" onClick={props.onCancelClick}>{props.cancelText.toUpperCase()}</a>
            </span>
    }
    return (
        <div className="row">
            <div className="text-right">
                <label className="col-md-3"></label>
                <div className="col-md-9">
                    {button}
                    {cancel}
                </div>
            </div>
        </div>
    )
}

export default PageFooter