import React from 'react'
import {authenticationService} from "../../services/authentication.service";
import FormRow from "../../components/ecocoin/FormRow";
import PageTitle from "../../components/ecocoin/PageTitle";
import PageFooter from "../../components/ecocoin/PageFooter";
import {roleOptions, timezoneOptions} from "../../helpers/data";


class UserAdd extends React.Component {

    state = {
        user: {
            firstName: '',
            lastName: '',
            username: '',
            nickname: '',
            password: '',
            activated: true,
            active: true,
            userId: 0,
            clientId: 1,
            teamId: 1,
            roleId: 2,
            clientIntroText: '',
            clientDomains: [],
            testVariation: '',
            timeZone: 'Europe/Amsterdam',
            requireDoi: true
        }
    }

    render() {
        var roleSpecific = ''
        if (this.state.user.roleId === 1) { // ADMIN
        } else if (this.state.user.roleId === 2) { // USER
            roleSpecific =
                <div>
                    <FormRow label="Client ID" type="number" value={this.state.user.clientId}
                             onChange={this.setClientId}/>
                    <FormRow label="Team ID" type="number" value={this.state.user.teamId}
                             onChange={this.setTeamId}/>
                </div>
        } else if (this.state.user.roleId === 3) { // CLIENT
            roleSpecific =
                <div>
                    <FormRow label="Email domains" type="textList" value={this.state.user.clientDomains}
                             onChange={this.setClientDomains}/>
                    <FormRow label="Intro text" type="textarea" rows="3" value={this.state.user.clientIntroText}
                             onChange={this.setClientIntroText}/>
                    <FormRow
                        label="Timezone"
                        type="select"
                        options={timezoneOptions}
                        value={this.state.user.timeZone}
                        onChange={this.setTimeZone}
                    />
                    <FormRow label="Require DOI" type="checkbox" value={this.state.user.requireDoi}
                             onChange={this.setRequireDoi}/>
                </div>
        }
        return (
            <div className="container-fluid">
                <PageTitle title="Add User"/>
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>General</h4>
                                </div>
                                <div className="content">

                                    <FormRow label="First name" type="text" value={this.state.user.firstName}
                                             onChange={this.setFirstName}/>
                                    <FormRow label="Last name" type="text" value={this.state.user.lastName}
                                             onChange={this.setLastName}/>
                                    <FormRow label="Username" type="text" value={this.state.user.nickname}
                                             onChange={this.setNickname}/>
                                    <FormRow label="Email" type="email" value={this.state.user.username}
                                             onChange={this.setUsername}/>
                                    <FormRow label="Password" type="text" value={this.state.user.password}
                                             onChange={this.setPassword}/>
                                    <FormRow label="Active" type="checkbox" value={this.state.user.active}
                                             onChange={this.setActive}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>Role</h4>
                                </div>
                                <div className="content">
                                    <FormRow
                                        label="Role"
                                        type="select"
                                        options={roleOptions}
                                        value={this.state.user.roleId}
                                        onChange={this.setRoleId}
                                    />
                                    {roleSpecific}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4>A/B Testing</h4>
                                </div>
                                <div className="content">
                                    <FormRow label="Variation" type="text" value={this.state.user.testVariation}
                                             onChange={this.setTestVariation}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter buttonText="Add" cancelText="Cancel" onCancelClick={this.cancel}/>
                </form>
            </div>

        )
    }

    setFirstName = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                firstName: e.target.value
            }
        })
    }

    setLastName = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                lastName: e.target.value
            }
        })
    }

    setUsername = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                username: e.target.value
            }
        })
    }

    setNickname = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                nickname: e.target.value
            }
        })
    }

    setPassword = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                password: e.target.value
            }
        })
    }

    setActive = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                active: e.target.checked
            }
        })
    }

    setClientId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                clientId: e.target.value
            }
        })
    }

    setTeamId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                teamId: e.target.value
            }
        })
    }

    setRoleId = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                roleId: parseInt(e.target.value, 10)
            }
        })
    }

    setTestVariation = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                testVariation: e.target.value
            }
        })
    }

    setClientIntroText = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                clientIntroText: e.target.value
            }
        })
    }

    setClientDomains = (domains) => {
        this.setState({
            user: {
                ...this.state.user,
                clientDomains: domains
            }
        })
    }

    setTimeZone = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                timeZone: e.target.value
            }
        })
    }

    setRequireDoi = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                requireDoi: e.target.checked
            }
        })
    }

    cancel = e => {
        e.preventDefault()
        window.history.back()
    }

    onSubmit = e => {
        e.preventDefault()
        console.log("submit", this.state.user)
        authenticationService.insert(this.state.user)
            .then(e => window.history.back())
    }
}

export default UserAdd
