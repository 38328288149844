import React from "react"
import Gramms from "./Gramms";
import Ecos from "./Ecos";

function ViewRow(props) {
    if (props.type === 'image') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9 view-value">
                    <img className="img-thumbnail" alt="None" src={props.value}/>
                </div>
            </div>
        )

    } else if (props.type === 'image-wide') {
        return (
            <div className="form-horizontal">
                <img className="img-thumbnail" alt="None"
                     src={props.value}/>
            </div>
        )

    } else if (props.type === 'html') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9 view-value" dangerouslySetInnerHTML={{__html: props.value}}/>
            </div>
        )

    } else if(props.type === 'gramms') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9 view-value">
                    <Gramms value={props.value}/>
                </div>
            </div>
        )

    } else if(props.type === 'ecos') {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9 view-value">
                    <Ecos value={props.value}/>
                </div>
            </div>
        )

    } else {
        return (
            <div className="form-group">
                <label className="col-md-3 control-label">{props.label}</label>
                <div className="col-md-9 view-value">
                    {props.value}
                </div>
            </div>
        )
    }
}

export default ViewRow
