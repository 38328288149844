import React from "react";
import Checkbox from "../FormInputs/Checkbox";
import update from 'immutability-helper';


class OpeningTimesForm extends React.Component {

    state = {
        earnDates: []
    }

    earnDates = [
        {dayOfWeek: 1, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 2, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 3, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 4, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 5, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 6, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"},
        {dayOfWeek: 7, active: false, fromHr: "08", fromMin: "00", toHr: "17", toMin: "00"}
    ]

    componentWillReceiveProps(props) {
        console.log("CCC", props.value)
        for (let i = 0; i < 7; i++) {
            this.earnDates[i].active = false
        }
        for (let i = 0; i < props.value.length; i++) {
            const from = props.value[i].startTime.split(":")
            const to = props.value[i].endTime.split(":")
            this.earnDates[props.value[i].dayOfWeek - 1] = {
                dayOfWeek: props.value[i].dayOfWeek,
                active: true,
                fromHr: from[0],
                fromMin: from[1],
                toHr: to[0],
                toMin: to[1]
            }
        }
        console.log("UPDATE SPEC", this.earnDates)
        this.setState({
            earnDates: this.earnDates
        })
    }

    weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
    minutes = ["00", "15", "30", "45"]

    render() {
        const hourOptions = this.hours.map((hour, index) =>
            <option key={index}>{hour}</option>
        )
        const minuteOptions = this.minutes.map((minute, index) =>
            <option key={index}>{minute}</option>
        )
        const dayline = this.state.earnDates.map((date, index) =>
            <div key={index} className="form-inline">
                <div className="form-row">
                    <div className="col-sm-2">
                        <Checkbox
                            key={index}
                            input={{
                                checked: this.state.earnDates[index].active,
                                onChange: this.handleCheckboxChange.bind(this, index)
                            }}
                            label={this.weekDays[index]}
                            type="checkbox"
                            meta="error"
                        />
                    </div>
                    <div className="col-sm-1">
                        From
                    </div>
                    <div className="col-sm-2">
                        <select className="form-control"
                                value={this.state.earnDates[index].fromHr}
                                onChange={this.setFromHour.bind(this, index)}>
                            {hourOptions}
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <select className="form-control"
                                value={this.state.earnDates[index].fromMin}
                                onChange={this.setFromMin.bind(this, index)}>
                            {minuteOptions}
                        </select>
                    </div>
                    <div className="col-sm-1">
                        until
                    </div>
                    <div className="col-sm-2">
                        <select className="form-control"
                                value={this.state.earnDates[index].toHr}
                                onChange={this.setToHour.bind(this, index)}>
                            {hourOptions}
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <select className="form-control"
                                value={this.state.earnDates[index].toMin}
                                onChange={this.setToMin.bind(this, index)}>
                            {minuteOptions}
                        </select>
                    </div>
                </div>
            </div>
        )
        return (
            <div>
                {dayline}
            </div>
        )
    }

    handleCheckboxChange = (index, e) => {
        console.log("CHECKBOX", index, e.target.checked)
        const newState = {
            earnDates: update(this.state.earnDates, {[index]: {active: {$set: e.target.checked}}})
        }
        this.setState(newState)
        this.updated(newState)
    }

    setFromHour = (index, e) => {
        const newState = {
            earnDates: update(this.state.earnDates, {[index]: {fromHr: {$set: e.target.value}}})
        }
        this.setState(newState)
        this.updated(newState)
    }

    setFromMin = (index, e) => {
        const newState = {
            earnDates: update(this.state.earnDates, {[index]: {fromMin: {$set: e.target.value}}})
        }
        this.setState(newState)
        this.updated(newState)
    }

    setToHour = (index, e) => {
        const newState = {
            earnDates: update(this.state.earnDates, {[index]: {toHr: {$set: e.target.value}}})
        }
        this.setState(newState)
        this.updated(newState)
    }

    setToMin = (index, e) => {
        const newState = {
            earnDates: update(this.state.earnDates, {[index]: {toMin: {$set: e.target.value}}})
        }
        this.setState(newState)
        this.updated(newState)
    }

    updated(state) {
        const dates = state.earnDates
            .filter(earnDate => earnDate.active)
            .map(earnDate => {
                return {
                    dayOfWeek: earnDate.dayOfWeek,
                    startTime: earnDate.fromHr + ':' + earnDate.fromMin + ":00",
                    endTime: earnDate.toHr + ':' + earnDate.toMin + ":00"
                }
            })
        this.props.onChange(dates)
        console.log("inchange", dates)
    }
}

export default OpeningTimesForm