import React from "react"
import FormRow from "./FormRow"
import Checkbox from "../FormInputs/Checkbox";

function FormRowWithCheckbox(props) {
    return (
        <div className="row">
            <div className="col-md-10">
                { FormRow(props) }
            </div>
            <div className="col-md-2">
                <Checkbox
                    input={
                        {
                            checked: props.checked,
                            onChange: props.onCheckChange
                        }}
                    label={props.checkLabel}
                    type="checkbox"
                    meta="error"
                />
            </div>
        </div>
    )
}

export default FormRowWithCheckbox
