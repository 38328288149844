import React from 'react'
import {authenticationService} from "../../services/authentication.service"
import ViewRow from "../../components/ecocoin/ViewRow"
import PageTitle from "../../components/ecocoin/PageTitle"
import {transactionsService} from "../../services/transactions.service";
import {roleName} from "../../helpers/data";


class UserView extends React.Component {

    state = {
        user: {},
        balance: {
            balance: 0,
            co2Saved: 0
        }
    }

    componentWillMount() {
        const userId = this.props.match.params.id
        authenticationService.get(userId).then(user => {
            this.setState({user: user})
            console.log("Loaded user", user)
        })
        transactionsService.userBalance(userId).then(balance => {
            this.setState({balance: balance})
        })
    }

    render() {
        var roleSpecific = ''
        if (this.state.user.roleId === 1) { // ADMIN
        } else if (this.state.user.roleId === 2) { // USER
            roleSpecific =
                <div>
                    <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                        <ViewRow label="Client ID" value={this.state.user.clientId}/>
                    </div>
                    <ViewRow label="Team ID" value={this.state.user.teamId}/>
                </div>
        } else if (this.state.user.roleId === 3) { // CLIENT
            roleSpecific =
                <div>
                    <ViewRow label="Email Domains" value={this.state.user.clientDomains.join(', ')}/>
                    <ViewRow label="Intro Text" type="html" value={this.state.user.clientIntroText}/>
                    <ViewRow label="Timezone" value={this.state.user.timeZone}/>
                    <ViewRow label="Require DOI" value={this.state.user.requireDoi ? "yes" : "no"}/>
                </div>
        }

        return (
            <div className="container-fluid">
                <PageTitle title={this.state.user.username} buttonText={authenticationService.isAdmin ? "Edit" : ""}
                           onButtonClick={this.gotoEdit}
                           withBackButton="true" onBackButtonClick={this.goBack}
                           subTitle={"USER-" + this.state.user.userId}/>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="header">
                                <h4>General</h4>
                            </div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <ViewRow label="First name" value={this.state.user.firstName}/>
                                    <ViewRow label="Last name" value={this.state.user.lastName}/>
                                    <ViewRow label="Username" value={this.state.user.nickname}/>
                                    <ViewRow label="E-Mail" value={this.state.user.username}/>
                                    <ViewRow label="Verified" value={this.state.user.activated ? "yes" : "no"}/>
                                    <ViewRow label="Active" value={this.state.user.active ? "yes" : "no"}/>

                                    <ViewRow label="User ID" value={this.state.user.userId}/>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="header">
                                <h4>Role</h4>
                            </div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <ViewRow label="Role" value={roleName(this.state.user.roleId)}/>
                                    {roleSpecific}
                                </div>
                            </div>
                        </div>
                        <div style={authenticationService.isClient ? {display: 'none'} : {}}>
                            <div className="card">
                                <div className="header">
                                    <h4>A/B Testing</h4>
                                </div>
                                <div className="content">
                                    <div className="form-horizontal">
                                        <ViewRow label="Variation" value={this.state.user.testVariation}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="header">
                                <h4>Transactions</h4>
                            </div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <ViewRow type="ecos" label="Balance" value={this.state.balance.balance}/>
                                    <ViewRow type="gramms" label="CO2e saved" value={this.state.balance.co2Saved}/>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="header">
                                <h4>Activity</h4>
                            </div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <ViewRow label="Created at" value={this.state.user.createdAt}/>
                                    <ViewRow label="Last login date" value={this.state.user.lastLoginDate}/>
                                    <ViewRow label="Last login host" value={this.state.user.lastLoginHost}/>
                                    <ViewRow label="Last login IP" value={this.state.user.lastLoginIp}/>
                                    <ViewRow label="Last password change date"
                                             value={this.state.user.lastPasswordChangeDate}/>
                                    <ViewRow label="Number of logins" value={this.state.user.nbLogin}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoEdit = () => {
        window.location = "/#/users/" + this.props.match.params.id + "/edit"
    }

    goBack = e => {
        e.preventDefault()
        window.history.back()
    }
}

export default UserView

